import { useContext } from 'react';
import { HandleAuthenticationContext } from '../ScribaSpecific/HandleAuthentication';
const getLanguageHeader = () => '';

export default function usePersonneApi() {
    const { token } = useContext(HandleAuthenticationContext);
    const baseUrl = 'api/personne';

    const detaillerComplet = async (id) => {
        var url = `${baseUrl}/complet/${id}`;
        var parameters = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
                ...await getLanguageHeader()
            },
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    };

    const listerComplet = async () => {
        var url = `${baseUrl}/listerComplet`;
        var parameters = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
                ...await getLanguageHeader()
            },
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    };

    const enregistrerAvecMagasin = async (item, magasinSelection) => {
        var url = `${baseUrl}`;
        var parameters = {
            method: 'POST',
            body: JSON.stringify({ ...item, magasins: magasinSelection }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                ...await getLanguageHeader()
            },
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    }

    const modifier = async (item) => {
        var url = `${baseUrl}`;
        var parameters = {
            method: 'PUT',
            body: JSON.stringify({ ...item }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                ...await getLanguageHeader()
            },
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    }

    const supprimer = async (idPersonne) => {
        var url = `${baseUrl}/${idPersonne}`;
        var parameters = {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                ...await getLanguageHeader()
            },
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    }

    return {
        detaillerComplet, modifier, enregistrerAvecMagasin, listerComplet, supprimer
    }

}