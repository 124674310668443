import React, { useEffect, useState, useMemo } from 'react';
import { TextField, Toggle, Panel, PanelType, Dropdown } from '@fluentui/react';
import usePersonneApi from '../../apis/usePersonneApi';
import useMagasinApi from '../../apis/useMagasinApi';
import { ScribaReactContexts, ScribaFluentUiComposants } from 'react-scriba-fluentui-web'
import Bouton from '../Generique/Bouton';
import SelectionSalarie from '../Salarie/SelectionSalarie';

const PersonneDetail = (props) => {
    const {
        visible,
        idPersonne,
        onFermer,
        onSucces
    } = props;

    const defaultItem = {
        idPersonne: '00000000-0000-0000-0000-000000000000',
        email: null,
        administrateurGlobal: false,
        matricule: null,
        magasinDtos: [],
        estResponsableMagasin: false
    }

    const { DialogOkCancel } = ScribaFluentUiComposants;
    const { setErrorMessage, setSuccessMessage, withLoading, getApiResponseJson, getApiResponse } = ScribaReactContexts.GlobalActions.useScribaGlobalActions();
    const [item, setItem] = useState(defaultItem);
    const [magasins, setMagasins] = useState([]);
    const personneApi = usePersonneApi();
    const magasinApi = useMagasinApi();
    const [dialogSuppressionVisible, setDialogSuppressionVisible] = useState(false);

    useEffect(() => {
        chargementComboBox();
    }, []);

    useEffect(() => {
        if (idPersonne)
            withLoading(() => chargementDonnees(idPersonne));

        return () => setItem(defaultItem);
    }, [idPersonne]);

    async function chargementComboBox() {
        const r = await getApiResponseJson(() => magasinApi.lister());
        if (r && !r.estErreur) {
            setMagasins(r);
        }
    }

    async function chargementDonnees(id) {
        const r = await getApiResponseJson(() => personneApi.detaillerComplet(id));
        if (r && !r.estErreur) {
            setItem(r);
        }
    }

    async function onChangeResponsableMagasin(elem) {
        if (elem.selected) {
            var magasin = magasins.filter(x => x.codeMagasin == elem.key);
            const test = item.magasinDtos;
            test.push(magasin[0]);
            setItem({ ...item, magasinDtos: test});
        } else {
            setItem({ ...item, magasinDtos: item.magasinDtos.filter(i => i.codeMagasin !== elem.key) });
        }  
    }

    async function onEnregistrer() {
        withLoading(() => getApiResponse(() => personneApi.enregistrerAvecMagasin(item)).then(r => {
            if (r.ok) {
                setSuccessMessage(`L'utilisateur a été enregistré avec succès.`);
                onSucces();
                onFermer();
            }
        }));
    }

    async function onSupprimer() {
        withLoading(() => getApiResponse(() => personneApi.supprimer(idPersonne)).then(r => {
            if (r.ok) {
                setSuccessMessage(`L'utilisateur a bien été supprimé.`);
                onDissimuleSuppressionDialog();
                onSucces();
                onFermer();
            }
        }));
    }

    const magasinOptions = useMemo(() => magasins.map(g => ({ key: g.codeMagasin, text: g.nomMagasin })), [magasins]);
    const selectedKeys = useMemo(() => item && item.magasinDtos.map(x => { return x.codeMagasin }), [item]);

    const creationContent = () => {
        return (<>
            <Panel
                headerText={`${(!idPersonne ? 'Nouvel' : 'Édition')} utilisateur`}
                type={PanelType.medium}
                isOpen={visible}
                onRenderFooterContent={onRenderFooterContent}
                isBlocking={false}
                onDismiss={onFermer}
                closeButtonAriaLabel="Fermer"
                isFooterAtBottom={true}
            >
                <div className="row">
                    <TextField
                        autoFocus
                        required
                        className="col-12"
                        label="Email"
                        value={item.email}
                        onChange={(e, v) => setItem({ ...item, email: v })}
                    />
                    <SelectionSalarie
                        onChange={(e) => setItem({ ...item, matricule: e })}
                        className="col-12"
                        matricule={item.matricule}
                    />
                    <Toggle
                        label="Administrateur"
                        className="col-4"
                        checked={item.administrateurGlobal}
                        onChange={(e, v) => setItem({ ...item, administrateurGlobal: v })}
                        onText="Oui"
                        offText="Non"
                    />
                    <Dropdown
                        label="Responsable magasin"
                        selectedKeys={selectedKeys}
                        onChange={(e, v) => onChangeResponsableMagasin(v)}
                        multiSelect
                        options={magasinOptions}
                    />
                </div>
            </Panel>
            <DialogOkCancel
                visible={dialogSuppressionVisible}
                title='Suppression'
                question='Confirmez-vous la suppression de cet élément ?'
                onOk={onSupprimer}
                onCancel={onDissimuleSuppressionDialog}
            />
        </>);
    }

    const onAfficheSuppressionDialog = () => {
        setDialogSuppressionVisible(true);
    };

    const onDissimuleSuppressionDialog = () => {
        setDialogSuppressionVisible(false);
    };

    const onRenderFooterContent = () => {
        return (<div>
            <Bouton type="Enregistrer" onClick={() => onEnregistrer()} />
            {idPersonne ? <Bouton type="Supprimer" onClick={() => onAfficheSuppressionDialog()} /> : null}
            <Bouton type="Annuler" onClick={() => onFermer()} />
        </div>);
    }

    return (creationContent());
}

export default PersonneDetail;