import { useContext } from 'react';
import { HandleAuthenticationContext } from '../ScribaSpecific/HandleAuthentication';
const getLanguageHeader = () => '';

export default function useFamilleApi() {
    const { token } = useContext(HandleAuthenticationContext);
    const baseUrl = 'api/famille';

    const listerFamilleSansPerimetreIndividuel = async (codeMagasin,annee, mois, codeRayon, matricule) => {
        var url = `${baseUrl}/${codeMagasin}/${annee}/${mois}/${matricule}/${codeRayon}`;
        var parameters = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
                ...await getLanguageHeader()
            },
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    };

    return {
        listerFamilleSansPerimetreIndividuel
    }
}