import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <div className="mb-2" style={{ width: "100%", marginRight: "auto", marginLeft: "auto" }}>
                <NavMenu />
                <div className="my-0 mx-20">
                    {this.props.children}
                </div>
            </div>
        );
    }
}
