import React, { Fragment, useEffect, useState, useContext, useMemo } from 'react';
import { DetailsList, IconButton, CommandBar, SelectionMode, Separator, Checkbox, ComboBox, Label, Link, Modal, Toggle, TextField } from '@fluentui/react';
import useMagasinValeursAccelerateurPrimeApi from '../../apis/useMagasinValeursAccelerateurPrimeApi';
import { scriba, ScribaReactContexts, ScribaFluentUiComposants } from 'react-scriba-fluentui-web'
import Tools from '../tools';
import { HandleMagasinMoisAnneeContext } from '../../ScribaSpecific/HandleMagasinMoisAnnee'
import { policies } from '../constants';
import AuthorizeComponent from '../Generique/AuthorizeComponent';

const MagasinValeursAccelerateurPrimeListe = (props) => {

    const { contains } = scriba.ScribaOutils;
    const { TextFieldNombre } = ScribaFluentUiComposants;
    const { setErrorMessage, setSuccessMessage, withLoading, getApiResponseJson, getApiResponse } = ScribaReactContexts.GlobalActions.useScribaGlobalActions();
    const magasinValeursAccelerateurPrimeApi = useMagasinValeursAccelerateurPrimeApi();
    const { codeMagasin, mois, annee } = useContext(HandleMagasinMoisAnneeContext);
    const dateActuelle = new Date();
    const [items, setItems] = useState([]);

    const dataColumns = [
        {
            key: 'MoisLibelle',
            name: 'Mois',
            ariaLabel: 'MoisLibelle',
            minWidth: 200,
            maxWidth: 300,
            isResizable: true,
            onRender: item => {
                const dJour = new Date();
                dJour.setMonth(item.mois - 1);
                return scriba.ScribaDate.stringDateFormat(dJour, 'MMMM')
            }
        },
        {
            key: 'NoteNPSObjectif',
            name: 'Note NPS Objectif',
            ariaLabel: 'NoteNPSObjectif',
            minWidth: 200,
            maxWidth: 300,
            isResizable: true,
            onRender: (item) => {
                const dateMaxModif = new Date(item.annee, item.mois, 15);
                return dateActuelle <= dateMaxModif
                    ? <TextFieldNombre
                        valeur={item.noteNPSObjectif}
                        setValeur={val => modifier({ ...item, noteNPSObjectif: val })}
                        suffix=''
                        precision={0}
                    />
                    : Tools.formatageNombreSeparateurMilliers(item.noteNPSObjectif);
            }
        },
        {
            key: 'NoteNPSRealise',
            name: 'Note NPS Réalisé',
            ariaLabel: 'NoteNPSRealise',
            minWidth: 200,
            maxWidth: 300,
            isResizable: true,
            onRender: (item) => {
                const dateMaxModif = new Date(item.annee, item.mois, 15);
                return dateActuelle <= dateMaxModif
                    ? <TextFieldNombre
                        valeur={item.noteNPSRealise}
                        setValeur={val => modifier({ ...item, noteNPSRealise: val })}
                        suffix=''
                        precision={0}
                    />
                    : Tools.formatageNombreSeparateurMilliers(item.noteNPSRealise);
            }
        },
        {
            key: 'TauxCATraceObjectif',
            name: 'Taux CA Tracé Objectif',
            ariaLabel: 'TauxCATraceObjectif',
            minWidth: 200,
            maxWidth: 300,
            isResizable: true,
            onRender: (item) => {
                const dateMaxModif = new Date(item.annee, item.mois, 15);
                return dateActuelle <= dateMaxModif
                    ? <TextFieldNombre
                        valeur={item.tauxCATraceObjectif}
                        setValeur={val => modifier({ ...item, tauxCATraceObjectif: val })}
                        suffix='%'
                        precision={2}
                    />
                    : Tools.formatageNombreSeparateurMilliers(item.tauxCATraceObjectif);
            }
        },
        {
            key: 'TauxCATraceRealise',
            name: 'Taux CA Tracé Réalisé',
            ariaLabel: 'TauxCATraceRealise',
            minWidth: 200,
            maxWidth: 300,
            isResizable: true,
            onRender: (item) => {
                const dateMaxModif = new Date(item.annee, item.mois, 15);
                return dateActuelle <= dateMaxModif
                    ? <TextFieldNombre
                        valeur={item.tauxCATraceRealise}
                        setValeur={val => modifier({ ...item, tauxCATraceRealise: val })}
                        suffix='%'
                        precision={2}
                    />
                    : Tools.formatageNombreSeparateurMilliers(item.tauxCATraceRealise);
            }
        },
    ];

    useEffect(() => {
        if (codeMagasin && mois && annee)
            withLoading(() => chargementDonnees());
    }, [codeMagasin, mois, annee]);

    async function chargementDonnees() {
        const r = await getApiResponseJson(() => magasinValeursAccelerateurPrimeApi.lister(codeMagasin, annee));
        if (r && !r.estErreur) {
            setItems(r);
        }
    }

    async function modifier(item) {
        const r = await getApiResponseJson(() => magasinValeursAccelerateurPrimeApi.enregistrer(item));
        if (r && !r.estErreur) {
            setSuccessMessage(`L'élément a été enregistré avec succès.`);
            setItems(st => st.map(x => {
                if (x.mois == item.mois)
                    return item;
                return x;
            }));
        }
    }

    async function onEnregistrement(item) {
        
    }


    const creationContent = () => {
        return (
            <AuthorizeComponent fonctionnalite={policies.magasinAccelerateurPrime}>
                <h2>Magasin - Accélérateur de prime</h2>
                <Separator />
                <DetailsList
                    setKey="items"
                    items={items}
                    columns={dataColumns}
                    selectionMode={SelectionMode.none}
                    onRenderRow={Tools.onRenderRowLignesAlternees}
                />
            </AuthorizeComponent >)
    }

    return (creationContent());
}

export default MagasinValeursAccelerateurPrimeListe