import React, { Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { DetailsList, IconButton, CommandBar, SelectionMode, Separator, ComboBox, Label } from '@fluentui/react';
import useSalarieApi from '../../apis/useSalarieApi';
import useSecteurApi from '../../apis/useSecteurApi';
import SalariePerimetreDetail from './SalariePerimetreDetail';
import { scriba, ScribaReactContexts } from 'react-scriba-fluentui-web'
import Tools from '../tools';
import RechercheLibre from '../Generique/RechercheLibre';
import { HandleMagasinMoisAnneeContext } from '../../ScribaSpecific/HandleMagasinMoisAnnee';
import { policies } from '../constants';
import AuthorizeComponent from '../Generique/AuthorizeComponent';

const SalariePerimetreListe = (props) => {

    const { contains } = scriba.ScribaOutils;
    const { setErrorMessage, setSuccessMessage, withLoading, getApiResponseJson, getApiResponse } = ScribaReactContexts.GlobalActions.useScribaGlobalActions();
    const secteurApi = useSecteurApi();
    const salarieApi = useSalarieApi();
    const { codeMagasin, mois, annee } = useContext(HandleMagasinMoisAnneeContext);

    const dataColumns = [
        {
            key: 'Matricule',
            name: 'Matricule',
            ariaLabel: 'Matricule',
            fieldName: 'matricule',
            minWidth: 100,
            maxWidth: 100,
            onColumnClick: _onColumnClick,
            isResizable: true
        },
        {
            key: 'NomSalarie',
            name: 'Salarié',
            ariaLabel: 'NomSalarie',
            fieldName: 'nomSalarie',
            minWidth: 200,
            onColumnClick: _onColumnClick,
            isResizable: true
        },
        {
            key: 'NomSecteur',
            name: 'Secteur',
            ariaLabel: 'NomSecteur',
            fieldName: 'nomSecteur',
            minWidth: 250,
            maxWidth: 400,
            onColumnClick: _onColumnClick,
            isResizable: true
        },
        {
            key: 'LibelleEmploi',
            name: 'Emploi',
            ariaLabel: 'LibelleEmploi',
            fieldName: 'libelleEmploi',
            minWidth: 250,
            maxWidth: 400,
            onColumnClick: _onColumnClick,
            isResizable: true
        },
        {
            key: 'EstResponsableSecteur',
            name: 'Responsable secteur',
            ariaLabel: 'EstResponsableSecteur',
            fieldName: 'estResponsableSecteur',
            minWidth: 100,
            maxWidth: 200,
            onColumnClick: _onColumnClick,
            isResizable: true,
            onRender: (item) => {
                return item.estResponsableSecteur ? "Oui" : "Non";
            }
        },
        {
            key: 'EstResponsableMagasin',
            name: 'Responsable Magasin',
            ariaLabel: 'EstResponsableMagasin',
            fieldName: 'estResponsableMagasin',
            minWidth: 100,
            maxWidth: 200,
            onColumnClick: _onColumnClick,
            isResizable: true,
            onRender: (item) => {
                return item.estResponsableMagasin ? "Oui" : "Non";
            }
        }
    ];

    const [items, setItems] = useState([]);
    const [columns, setColumns] = useState(dataColumns);
    const [panelDetailVisible, setPanelDetailVisible] = useState(false);
    const [rechercheLibreFilter, setRechercheLibreFilter] = useState('');
    const [idSecteur, setIdSecteur] = useState(null);
    const [matricule, setMatricule] = useState(null);
    const [secteurs, setSecteurs] = useState([]);
    const [responsableSecteur, setResponsableSecteur] = useState(null);
    const refItems = useRef(items);

    useEffect(() => {
        if (codeMagasin, mois, annee) {
            chargementDonneesStatiques(codeMagasin, annee, mois);
            setIdSecteur(null)
            setResponsableSecteur(null)
        }
    }, [codeMagasin, mois, annee]);

    useEffect(() => {
        if (codeMagasin && mois && annee)
            withLoading(() => chargementDonnees(codeMagasin, annee, mois, idSecteur)); // idSecteur peut etre rempli ou null
    }, [codeMagasin, mois, annee, idSecteur]);

    useEffect(() => {
        if (idSecteur)
            chargementResponsableDeSecteur()
    }, [idSecteur]);

    async function chargementDonneesStatiques(magasinCode, an, m) {
        const r = await getApiResponseJson(() => secteurApi.listerContenantSalarie(magasinCode, an, m));
        if (r && !r.estErreur) {
            setSecteurs(r);
        }
    }

    async function chargementDonnees(magasinCode, an, m, secteurId) {
        const r = await getApiResponseJson(() => salarieApi.listerAvecResponsable(magasinCode, an, m, secteurId));
        if (r && !r.estErreur) {
            refItems.current = r;
            setColumns(dataColumns);
            setItems(r);
        }
    }

    async function chargementResponsableDeSecteur() {
        const r = await getApiResponseJson(() => secteurApi.detailler(idSecteur, codeMagasin));

        if (r && !r.estErreur) {
            setResponsableSecteur(r.nomSalarieResponsableSecteur);
        }
    }

    const secteurOptions = useMemo(() => [
        { key: null, text: "Sélectionnez un secteur" },
        ...secteurs.map(secteur => ({ key: secteur.idSecteur, text: secteur.nomSecteur }))]
        , [secteurs]);

    const creationContent = () => {
        const itemsFiltered = (!items ? [] : (items.length > 0 ? items.filter(i =>
            contains(rechercheLibreFilter, i.matricule) ||
            contains(rechercheLibreFilter, i.nomSalarie)) : items));

        return (
            <AuthorizeComponent fonctionnalite={policies.magasinSalariePerimetreIndividuel}>
                <h2>Liste des salariés et périmètre individuel</h2>
                <CommandBar
                    items={getCommandBarItems}
                    farItems={getCommandBarFarItems}
                />
                <DetailsList
                    setKey="items"
                    items={itemsFiltered}
                    columns={[...columns, ...actionColumns]}
                    selectionMode={SelectionMode.none}
                    onRenderRow={Tools.onRenderRowLignesAlternees}
                />
                <SalariePerimetreDetail
                    visible={panelDetailVisible}
                    matricule={matricule}
                    codeMagasin={codeMagasin}
                    annee={annee}
                    mois={mois}
                    onFermer={onDissimuleEditPanel}
                />
            </AuthorizeComponent>)
    }

    function onAfficheEditPanel(item) {
        setMatricule(item == null ? null : item.matricule);
        setPanelDetailVisible(true);
    }
    function onDissimuleEditPanel() {
        setMatricule(null);
        setPanelDetailVisible(false);
    }

    const actionColumns = [
        {
            key: 'modifier',
            name: '',
            ariaLabel: 'Modifier',
            minWidth: 20,
            maxWidth: 40,
            isIconOnly: true,
            isSorted: false,
            onRender: (item) => (
                <IconButton
                    iconProps={{ iconName: 'OpenPane' }}
                    title="Modifier"
                    ariaLabel="Modifier"
                    onClick={() => onAfficheEditPanel(item)}
                />
            )
        }
    ];

    function _onColumnClick(ev, column) {
        const sortedValues = Tools.sortColumns(refItems.current, columns, column);
        setItems(sortedValues[0]);
        setColumns(sortedValues[1]);
    }

    const getCommandBarItems = [
        {
            key: 'secteurResponsable',
            onRender: () => <RechercheLibre
                disabled={!items || items.length < 1}
                value={rechercheLibreFilter}
                onChange={(e) => setRechercheLibreFilter(e)}
                customStyles={{ marginRight: '1rem' }}
            />
        },
        {
            key: 'secteurSelection',
            onRender: () => <div style={{ display: 'flex', alignItems: 'center' }}>
                <ComboBox
                    selectedKey={idSecteur}
                    options={secteurOptions}
                    onChange={(e, opt, i, v) => {
                        setIdSecteur(opt.key)
                    }}
                    disabled={secteurOptions.length < 2}
                />
                {responsableSecteur &&
                    <>
                        <span style={{ marginLeft: '10px', marginRight: '10px', fontWeight: '600', textDecoration:'underline' }}>Responsable de secteur</span>
                        <span>{responsableSecteur}  </span>
                    </>}
            </div>
        }
    ];
    const getCommandBarFarItems = [];

    return (creationContent());
}

export default SalariePerimetreListe