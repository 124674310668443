import React, { useEffect, useState } from 'react';
import { TextField, Toggle, Panel, PanelType, Label, Separator, Text } from '@fluentui/react';
import useSalarieApi from '../../apis/useSalarieApi';
import { scriba, ScribaReactContexts } from 'react-scriba-fluentui-web'
import Bouton from '../Generique/Bouton';
import SelectionSalarie from '../Salarie/SelectionSalarie';
import PerimetreIndividuelListe from './PerimetreIndividuelListe';

const SalariePerimetreDetail = (props) => {
    const {
        visible,
        matricule,
        onFermer,
        codeMagasin,
        annee,
        mois
    } = props;

    const defaultItem = {
    }

    const { stringDateFormatCourt } = scriba.ScribaDate;
    const { setErrorMessage, setSuccessMessage, withLoading, getApiResponseJson, getApiResponse } = ScribaReactContexts.GlobalActions.useScribaGlobalActions();
    const [item, setItem] = useState(defaultItem);
    const salarieApi = useSalarieApi();

    useEffect(() => {
        if (codeMagasin && annee && mois && matricule)
            withLoading(() => chargementDonnees(codeMagasin, annee, mois, matricule));

        return () => setItem(defaultItem);
    }, [codeMagasin, annee, mois, matricule]);

    async function chargementDonnees(magasinCode, annee, mois, id) {
        const r = await getApiResponseJson(() => salarieApi.detaillerComplet(magasinCode, annee, mois, id));
        if (r && !r.estErreur) {
            setItem(r);
        }
    }

    const creationContent = () => {
        return (<>
            <Panel
                headerText={`Modification - salarié ${matricule}`}
                type={PanelType.large}
                isOpen={visible}
                onRenderFooterContent={onRenderFooterContent}
                isBlocking={false}
                onDismiss={onFermer}
                closeButtonAriaLabel="Fermer"
                isFooterAtBottom={true}
            >
                <div className="row">
                    <div className="col-6">
                        <Label>Matricule</Label>
                        <Text>{item?.matricule}</Text>
                        <Label>Nom</Label>
                        <Text>{item?.nomSalarie}</Text>
                        <Label>Famille d'emploi</Label>
                        <Text>{item?.familleEmploiDto?.familleEmploi1}</Text>
                        <Label>Emploi</Label>
                        <Text>{item?.libelleEmploi}</Text>
                        <Label>Ancienneté</Label>
                        <Text>{item && stringDateFormatCourt(item.dateAncienneteGroupe)}</Text>
                    </div>
                    <div className="col-6">
                        <Label>Magasin</Label>
                        <Text>{item?.magasinDto?.nomMagasin}</Text>
                        <Label>Secteur</Label>
                        <Text>{item?.secteurDto?.nomSecteur}</Text>
                    </div>
                </div>
                <Separator />
                <div>
                    {item?.magasinDto?.utilisationPerimetreIndividuel && <PerimetreIndividuelListe matricule={matricule} possedeSecteur={!!item?.secteurDto} />}
                    {!item?.magasinDto?.utilisationPerimetreIndividuel && <p>Le paramétrage du magasin n'autorise pas le renseignement des périmètres individuels d'un salarié.</p>}
                </div>
            </Panel>
        </>);
    }

    const onRenderFooterContent = () => {
        return (<div>
            <Bouton type="Fermer"  onClick={() => onFermer()} />
        </div>);
    }

    return (creationContent());
}

export default SalariePerimetreDetail;