import React, { useState } from 'react';
const dateJour = new Date();
dateJour.setMonth(dateJour.getMonth() + 1);
const HandleMagasinMoisAnneeContext = React.createContext({});

const HandleMagasinMoisAnneeProvider = (props) => {
    const [codeMagasin, setCodeMagasin] = useState(null);
    const [mois, setMois] = useState(dateJour.getMonth());
    const [annee, setAnnee] = useState(dateJour.getFullYear());

    return <HandleMagasinMoisAnneeContext.Provider value={{ codeMagasin, mois, annee, setCodeMagasin, setMois, setAnnee }}>
        {props.children}
    </HandleMagasinMoisAnneeContext.Provider>
}

export { HandleMagasinMoisAnneeContext, HandleMagasinMoisAnneeProvider };