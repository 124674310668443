import { useContext } from 'react';
import { HandleAuthenticationContext } from '../ScribaSpecific/HandleAuthentication';
const getLanguageHeader = () => '';

export default function useMagasinMoisGereApi() {
    const { token } = useContext(HandleAuthenticationContext);
    const baseUrl = 'api/magasinMoisGere';

    const lister = async (codeMagasin, annee, mois) => {
        var url = `${baseUrl}/${codeMagasin}/${annee}/${mois}`;
        var parameters = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
                ...await getLanguageHeader()
            },
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    };

    const initialiser = async (codeMagasin, annee, mois) => {
        var url = `${baseUrl}/initialiser`;
        var parameters = {
            method: 'POST',
            body: JSON.stringify({ codeMagasin, annee, mois }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                ...await getLanguageHeader()
            },
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    }


    return {
        lister, initialiser
    }
}