import { useContext } from 'react';
import { HandleAuthenticationContext } from '../ScribaSpecific/HandleAuthentication';
const getLanguageHeader = () => '';

export default function useVenteMagasinGroupeApi() {
    const { token } = useContext(HandleAuthenticationContext);
    const baseUrl = 'api/venteMagasinGroupe';

    const recupereAnnéesAffichage = async () => {
        var url = `${baseUrl}`;
        var parameters = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
                ...await getLanguageHeader()
            },
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    };

    return {
        recupereAnnéesAffichage
    }
}