import React, { Component, useState, useEffect, useContext, useCallback, useRef } from 'react';
import Tools from '../components/tools';

export const HandleAuthenticationContext = React.createContext({});

export function HandleAuthenticationProvider(props) {
    const { credentials } = props;

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [token, setToken] = useState(null);
    const [afficheLienConnexion, setAfficheLienConnexion] = useState(false);
    const [afficheLienRafraichissement, setAfficheLienRafraichissement] = useState(false);

    useEffect(() => {
        if (!credentials || !credentials.clientId)
            return;

        window.google.accounts.id.initialize({
            client_id: credentials.clientId,
            callback: data => handleCredentialResponse(data),
            cancel_on_tap_outside: false
        })
        window.google.accounts.id.renderButton(
            document.getElementById("buttonDivGgAuth"), // Ensure the element exist and it is a div to display correctly
            { theme: "outline", size: "large" }  // Customization attributes
        );

        if (!isAuthenticated) {
            // Display the One Tap dialog
            window.google.accounts.id.prompt((notification) => {
                if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
                    console.log("gg auth not displayed reason", notification.getNotDisplayedReason())
                    document.cookie = `g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
                    window.google.accounts.id.prompt()
                    setAfficheLienConnexion(true);
                }
            });
        }
    }, [credentials, isAuthenticated])

    const logOut = useCallback(() => {
        if (!token) {
            console.error("aucun identifiant de connexion n'est disponible lors de la demande de déconnexion");
            return;
        }

        const { sub } = Tools.parseJwt(token);
        window.google.accounts.id.revoke(sub, done => { console.log(done.error); });
        document.location.reload();
    }, [token]);

    function handleCredentialResponse(response) {
        console.log("Récupération du token ", response !== null);
        setIsAuthenticated(response !== null);
        setToken(response.credential);
    }

    function anonymous() {
        return <div className="unAuthenticated">
            <div><h1>HERMIONE - REMV TPR</h1></div>
            <div>Vous n'êtes pas authentifié sur l'application.</div>
            <div>Procédez à l'authentification à l'aide de votre&nbsp;<a target="_blank" href="https://accounts.google.com">compte gmail</a></div>
        </div>
    }

    return (
        <HandleAuthenticationContext.Provider value={{ isAuthenticated, token, logOut }}>
            {isAuthenticated && props.children}
            {!isAuthenticated && anonymous()}
        </HandleAuthenticationContext.Provider>
    );
}