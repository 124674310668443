import { useContext } from 'react';
import { HandleAuthenticationContext } from '../ScribaSpecific/HandleAuthentication';
const getLanguageHeader = () => '';


export default function useAuthApi() {
    const { token } = useContext(HandleAuthenticationContext);
    const baseUrl = 'api/auth';

    const get = async (email) => {
        var url = `${baseUrl}/${window.btoa(email)}`;
        var parameters = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
                ...await getLanguageHeader()
            },
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    };

    return {
        get
    }
}