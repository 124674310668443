import { useContext } from 'react';
import { HandleAuthenticationContext } from '../ScribaSpecific/HandleAuthentication';
const getLanguageHeader = () => '';

export default function useVenteMagasinFamilleApi() {
    const { token } = useContext(HandleAuthenticationContext);
    const baseUrl = 'api/venteMagasinFamille';

    const listerComplet = async (codeMagasin, mois, annee, idSecteur, codeGroupe, codeRayon) => {
        var url = `${baseUrl}/complet`;
        var parameters = {
            method: 'POST',
            body: JSON.stringify({
                codeMagasin: codeMagasin,
                mois: mois,
                annee: annee,
                idSecteur: idSecteur,
                codeGroupe: codeGroupe,
                codeRayon: codeRayon
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                ...await getLanguageHeader()
            },
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    };

    const listerRepartitionCA = async (codeMagasin, mois, annee, codeGroupe) => {
        var url = `${baseUrl}/repartitionCA`;
        var parameters = {
            method: 'POST',
            body: JSON.stringify({
                codeMagasin: codeMagasin,
                mois: mois,
                annee: annee,
                codeGroupe: codeGroupe
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                ...await getLanguageHeader()
            },
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    };

    const listerMagasinAvecCARestantAVentiler = async (mois, annee) => {
        var url = `${baseUrl}/avecCARestantAVentiler/${mois}/${annee}`;
        var parameters = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
                ...await getLanguageHeader()
            },
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    };

    const enregistrer = async (item) => {
        var url = `${baseUrl}`;
        var parameters = {
            method: 'POST',
            body: JSON.stringify(item),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                ...await getLanguageHeader()
            },
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    }

    return {
        listerComplet, listerRepartitionCA, listerMagasinAvecCARestantAVentiler, enregistrer
    }
}