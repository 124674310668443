import React, { Fragment, useEffect, useState, useContext, useMemo } from 'react';
import { DetailsList, CommandBar, SelectionMode, Separator, ComboBox, Label, Link } from '@fluentui/react';
import useSalarieApi from '../../apis/useSalarieApi';
import useSecteurApi from '../../apis/useSecteurApi';
import { scriba, ScribaReactContexts } from 'react-scriba-fluentui-web'
import Tools from '../tools';
import { HandleMagasinMoisAnneeContext } from '../../ScribaSpecific/HandleMagasinMoisAnnee'

const SalariePrimeListe = (props) => {

    const { contains } = scriba.ScribaOutils;
    const { setErrorMessage, setSuccessMessage, withLoading, getApiResponseJson, getApiResponse } = ScribaReactContexts.GlobalActions.useScribaGlobalActions();
    const salarieApi = useSalarieApi();
    const secteurApi = useSecteurApi();
    const { codeMagasin, mois, annee } = useContext(HandleMagasinMoisAnneeContext);

    const dataColumns = [
        {
            key: 'Matricule',
            name: 'Matricule',
            ariaLabel: 'Matricule',
            fieldName: 'matricule',
            minWidth: 200,
            maxWidth: 300,
            onColumnClick: _onColumnClick,
            isResizable: true
        },
        {
            key: 'NomSalarie',
            name: 'Salarié',
            ariaLabel: 'NomSalarie',
            fieldName: 'nomSalarie',
            minWidth: 200,
            maxWidth: 300,
            onColumnClick: _onColumnClick,
            isResizable: true
        },
        {
            key: 'CAObjectifPerimetreIndividuel',
            name: 'Objectif périmètre individuel',
            ariaLabel: 'CAObjectifPerimetreIndividuel',
            minWidth: 200,
            maxWidth: 300,
            onColumnClick: _onColumnClick,
            isResizable: true,
            onRender: (item) => Tools.formatageNombreSeparateurMilliersAvecSigleMonetaire(item.caObjectifPerimetreIndividuel)
        },
        {
            key: 'CARealisePerimetreIndividuel',
            name: 'Réalisé périmètre individuel',
            ariaLabel: 'CARealisePerimetreIndividuel',
            minWidth: 200,
            maxWidth: 300,
            onColumnClick: _onColumnClick,
            isResizable: true,
            onRender: (item) => Tools.formatageNombreSeparateurMilliersAvecSigleMonetaire(item.caRealisePerimetreIndividuel)
        },
        {
            key: 'CAObjectifMagasin',
            name: 'Objectif magasin',
            ariaLabel: 'CAObjectifMagasin',
            minWidth: 200,
            maxWidth: 300,
            onColumnClick: _onColumnClick,
            isResizable: true,
            onRender: (item) => Tools.formatageNombreSeparateurMilliersAvecSigleMonetaire(item.caObjectifMagasin)
        },
        {
            key: 'CARealiseMagasin',
            name: 'Réalisé magasin',
            ariaLabel: 'CARealiseMagasin',
            minWidth: 200,
            maxWidth: 300,
            onColumnClick: _onColumnClick,
            isResizable: true,
            onRender: (item) => Tools.formatageNombreSeparateurMilliersAvecSigleMonetaire(item.caRealiseMagasin)
        },
        {
            key: 'MontantPrime',
            name: 'Prime',
            ariaLabel: 'MontantPrime',
            minWidth: 200,
            maxWidth: 300,
            onColumnClick: _onColumnClick,
            isResizable: true,
            onRender: (item) => Tools.formatageNombreSeparateurMilliersAvecSigleMonetaire(item.montantPrime)
        },
    ];

    const [items, setItems] = useState([]);
    const [columns, setColumns] = useState(dataColumns);
    const [secteurs, setSecteurs] = useState([]);
    const [idSecteur, setIdSecteur] = useState(null);
    const [responsableSecteur, setResponsableSecteur] = useState(null);

    useEffect(() => {
        if (codeMagasin && mois && annee)
            withLoading(() => chargementDonnees());
    }, [codeMagasin, mois, annee, idSecteur]);

    useEffect(() => {
        if (codeMagasin)
            chargementComboBox();
    }, [codeMagasin]);

    useEffect(() => {
        if (idSecteur)
            chargementResponsableDeSecteur()
    }, [idSecteur]);

    async function chargementDonnees() {
        const r = await getApiResponseJson(() => salarieApi.listerPrime(codeMagasin, annee, mois, idSecteur));

        if (r && !r.estErreur) {
            setItems(r);
        }
    }
    async function chargementComboBox() {
        const r = await getApiResponseJson(() => secteurApi.listerParMagasin(codeMagasin));

        if (r && !r.estErreur) {
            setSecteurs(r);
        }
    }
    async function chargementResponsableDeSecteur() {
        const r = await getApiResponseJson(() => secteurApi.detailler(idSecteur, codeMagasin));

        if (r && !r.estErreur) {
            setResponsableSecteur(r.nomSalarieResponsableSecteur);
        }
    }

    const secteurOptions = useMemo(() => [{ key: null, text: 'Sélectionnez un secteur' }, ...secteurs.map((e) => ({ key: e.idSecteur, text: e.nomSecteur }))], [secteurs]);

    const creationContent = () => {
        return (
            <Fragment>
                <CommandBar
                    items={getCommandBarItems}
                />
                <Separator />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', margin: '1rem' }}>
                    <ComboBox
                        className="col-2"
                        selectedKey={idSecteur}
                        options={secteurOptions}
                        onChange={(e, opt, i, v) => {
                            setIdSecteur(opt.key)
                        }}
                    />
                    {responsableSecteur &&
                        <>
                            <Label style={{ marginLeft: '10px', marginRight: '10px' }}>Resp. secteur</Label>
                            <Link underline>
                                {responsableSecteur}
                            </Link>
                        </>}
                </div>
                <DetailsList
                    setKey="items"
                    items={items}
                    columns={dataColumns}
                    selectionMode={SelectionMode.none}
                    onRenderRow={Tools.onRenderRowLignesAlternees}
                />
            </Fragment >)
    }

    function _onColumnClick(ev, column) {
        const sortedValues = Tools.sortColumns(items, columns, column);
        setItems(sortedValues[0]);
        setColumns(sortedValues[1]);
    }

    const getCommandBarItems = [
        {
            key: 'title',
            onRender: () => <h2>Salariés</h2>
        }
    ];

    return (creationContent());
}

export default SalariePrimeListe