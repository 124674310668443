const configApi = () => {
    const baseUrl = 'api/config';

    const get = async () => {
        var url = `${baseUrl}`;
        var parameters = {
            method: 'GET',
            headers: {},
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    };

    return {
        get
    }
}

export default configApi();