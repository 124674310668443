import React, { useEffect, useState } from 'react';
import {  TagPicker, Label    } from '@fluentui/react';
import { ScribaReactContexts } from 'react-scriba-fluentui-web';
import useSalarieApi from '../../apis/useSalarieApi';

const SelectionSalarie = (props) => {
    const {
        matricule,
        className,
        onChange,
        required,
        disabled
    } = props;

    const { setErrorMessage, withLoading, getApiResponseJson } = ScribaReactContexts.GlobalActions.useScribaGlobalActions();
    const [items, setItems] = useState([]);
    const salarieApi = useSalarieApi();

    useEffect(() => {
        if (matricule)
            withLoading(() => chargementDonnees());

        return () => { setItems([]) }
    }, [matricule]);

    async function chargementDonnees() {
        const r = await getApiResponseJson(() => salarieApi.detailler(matricule));
        if (r && !r.estErreur) {
            let tabSalarie = mapSalariePicker(r);
            setItems(tabSalarie);
        }
    }

    async function rechercheSalarieFiltre(filtre) {
        const r = await getApiResponseJson(() => salarieApi.rechercher(filtre));
        if (r && !r.estErreur)
            return mapSalariePicker(r);
    }

    async function selectionSalarie(items) {
        setItems(items);

        let matricule = null;
        if (items.length > 0) {
            matricule = items[0].key;
        }

        onChange(matricule);
    }

    function mapSalariePicker(r) {
        let tabSalarie = [];

        if (r) {
            if (Array.isArray(r)) { //Tableau d'objet => Recherche
                tabSalarie = r.map(bu => ({
                    key: bu.matricule,
                    name: bu.nomSalarie + ' (' + bu.matricule + ')'
                }));
            } else { //Objet => Chargement
                tabSalarie.push({
                    key: r.matricule,
                    name: r.nomSalarie + ' (' + r.matricule + ')'
                });
            }
        }

        return tabSalarie;
    }

    const creationContent = () => {
        return (
            <div className={className}>
                <Label required={required}>Salarié</Label>
                <div className="selectionComposantGenerique">
                    <>
                        <TagPicker
                            disabled={disabled}
                            inputProps={{ placeholder: 'Saisissez un matricule ou un nom de salarié' }}
                            removeButtonAriaLabel="Annuler"
                            onResolveSuggestions={rechercheSalarieFiltre}
                            selectedItems={items}
                            pickerSuggestionsProps={{ noResultsFoundText: 'Aucun résultat trouvé', }}
                            onChange={selectionSalarie}
                            itemLimit={1}
                            className="col-11"
                        />
                    </>
                </div>
            </div>
        );
    }

    return (creationContent());
}

export default SelectionSalarie;