import React, { Fragment } from 'react';
import { Icon, PrimaryButton } from '@fluentui/react';

export class HandleError extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            hasError: false,
            message: ''
        }
    }

    componentDidCatch(error, info) {
        console.error("ComponentDidCatch - Une erreur est survenue dans l'application SPA", error);
        console.log("ComponentDidCatch - Plus d'informations", info);

        return {
            hasError: true,
            message: error?.message
        };
    }

    render() {
        return (this.state.hasError ?
            <Fragment>
                <div style={{ width: '510px', margin: 'auto', marginTop: '190px', }}>
                    <div style={{ fontSize: '19px', marginBottom: '20px' }}>
                        <Icon iconName="Error" style={{ float: 'left', marginRight: '10px' }} /><span>Nous sommes désolés mais une erreur est survenue.</span>
                    </div>
                    <div style={{ marginBottom: '25px' }}>
                        {this.state.message}
                    </div>
                    <div>
                        <PrimaryButton iconProps={{ iconName: "Back" }} onClick={() => window.location.href = '/'}>Revenir à l'accueil</PrimaryButton>
                    </div>
                </div>
            </Fragment>
            : this.props.children)
    }
}