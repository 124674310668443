import { Home } from "./components/Home";
import PersonneListe from "./components/Personne/PersonneListe";
import FamilleEmploiListe from "./components/FamilleEmploi/FamilleEmploiListe";
import AffectationFamilleParSecteurListe from "./components/VenteMagasinFamille/AffectationFamilleParSecteurListe";
import SalariePerimetreListe from "./components/SalariePerimetre/SalariePerimetreListe";
import RepartitionCAListe from "./components/VenteMagasinFamille/RepartitionCAListe";
import InitialisationDonneesListe from "./components/Initialisation/InitialisationDonneesListe";
import MagasinValeursAccelerateurPrimeListe from "./components/MagasinValeursAccelerateurPrime/MagasinValeursAccelerateurPrimeListe";
import SalariePrimeListe from "./components/Salarie/SalariePrimeListe";
import PrimeCalculFiche from "./components/Prime/PrimeCalculFiche";

const AppRoutes = [
    {
        index: true,
        element: <SalariePrimeListe />
    },
    {
        path: '/personne',
        element: <PersonneListe />
    },
    {
        path: '/familleEmploi',
        element: <FamilleEmploiListe />
    },
    {
        path: '/familleSecteur',
        element: <AffectationFamilleParSecteurListe />
    },
    {
        path: '/salariePerimetre',
        element: <SalariePerimetreListe />
    },
    {
        path: '/repartitionCa',
        element: <RepartitionCAListe />
    },
    {
        path: '/initialisationDonnees',
        element: <InitialisationDonneesListe/>
    },
    {
        path: '/accelerateurPrime',
        element: <MagasinValeursAccelerateurPrimeListe />
    },
    {
        path: '/salariesPrime',
        element: <SalariePrimeListe />
    },
    {
        path: '/calculPrime',
        element: <PrimeCalculFiche />
    }
];

export default AppRoutes;
