import { useContext } from 'react';
import { HandleAuthenticationContext } from '../ScribaSpecific/HandleAuthentication';
const getLanguageHeader = () => '';

export default function useSecteurApi() {
    const { token } = useContext(HandleAuthenticationContext);
    const baseUrl = 'api/secteur';

    const listerContenantSalarie = async (codeMagasin, annee, mois) => {
        var url = `${baseUrl}/contenant-salarie/${codeMagasin}/${annee}/${mois}`;
        var parameters = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
                ...await getLanguageHeader()
            },
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    };

    const listerParMagasin = async (codeMagasin) => {
        var url = `${baseUrl}/${codeMagasin}`;
        var parameters = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
                ...await getLanguageHeader()
            },
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    };

    const detailler = async (idSecteur, codeMagasin) => {
        var url = `${baseUrl}/${idSecteur}/${codeMagasin}`;
        var parameters = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
                ...await getLanguageHeader()
            },
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    };

    return {
        detailler, listerParMagasin, listerContenantSalarie
    }
}