import React, { useEffect, useState, useMemo, useContext } from 'react';
import { ComboBox } from '@fluentui/react';
import { ScribaReactContexts } from 'react-scriba-fluentui-web';
import useMagasinApi from '../../apis/useMagasinApi';
import useVenteMagasinGroupeApi from '../../apis/useVenteMagasinGroupeApi';
import { HandleMagasinMoisAnneeContext } from '../../ScribaSpecific/HandleMagasinMoisAnnee';

const SelectionMagasinMoisAnnee = (props) => {
    const { idPersonne } = props
    const { setErrorMessage, withLoading, getApiResponseJson } = ScribaReactContexts.GlobalActions.useScribaGlobalActions();
    const { codeMagasin, mois, annee, setCodeMagasin, setMois, setAnnee } = useContext(HandleMagasinMoisAnneeContext);
    const [magasinListe, setMagasinListe] = useState([]);
    const [anneeListe, setAnneeListe] = useState([]);
    const magasinApi = useMagasinApi();
    const venteMagasinGroupeApi = useVenteMagasinGroupeApi();

    useEffect(() => {
        if (idPersonne)
            withLoading(() => chargementDonnees(idPersonne));
    }, [idPersonne]);

    async function chargementDonnees(id) {
        const r = await getApiResponseJson(() => magasinApi.listerParPersonne(id, annee, mois));
        if (r && !r.estErreur) {
            setMagasinListe(r);
            if (r.length >= 1)
                setCodeMagasin(r[0].codeMagasin)
        }

        const a = await getApiResponseJson(() => venteMagasinGroupeApi.recupereAnnéesAffichage());
        if (a && !a.estErreur) {
            setAnneeListe(a);
        }
    }

    const magasinOptions = useMemo(() => magasinListe.map(x => ({ key: x.codeMagasin, text: x.nomMagasin }))
        , [magasinListe])
    const anneeOptions = useMemo(() => anneeListe.map(x => ({ key: x, text: x }))
        , [anneeListe])

    const creationContent = () => {
        const moisListe = [
            { key: 1, text: 'Janvier' },
            { key: 2, text: 'Février' },
            { key: 3, text: 'Mars' },
            { key: 4, text: 'Avril' },
            { key: 5, text: 'Mai' },
            { key: 6, text: 'Juin' },
            { key: 7, text: 'Juillet' },
            { key: 8, text: 'Aout' },
            { key: 9, text: 'Septembre' },
            { key: 10, text: 'Octobre' },
            { key: 11, text: 'Novembre' },
            { key: 12, text: 'Décembre' }
        ];

        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '1rem' }}>
                <ComboBox
                    className="col-4"
                    selectedKey={codeMagasin}
                    options={magasinOptions}
                    onChange={(e, opt, i, v) => {
                        setCodeMagasin(opt.key)
                    }}
                    disabled={magasinListe.length < 2}
                />
                <ComboBox
                    className="col-3"
                    selectedKey={mois}
                    options={moisListe}
                    onChange={(e, opt, i, v) => {
                        setMois(opt.key)
                    }}
                />
                <ComboBox
                    className="col-2"
                    selectedKey={annee}
                    options={anneeOptions}
                    onChange={(e, opt, i, v) => {
                        setAnnee(opt.key)
                    }}
                />
            </div>
        );
    }

    return (creationContent());
}

export default SelectionMagasinMoisAnnee;