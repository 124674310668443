import React, { Component, useState, useEffect } from 'react';
import { ScribaReactContexts } from 'react-scriba-fluentui-web'

import configApi from '../apis/configApi';
import 'react-scriba-fluentui-web/dist/index.css'
import { createCustomAppTheme } from './FluentUiConfiguration'

import { ThemeProvider } from '@fluentui/react';
import { HandleError } from './HandleError';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { HandleAuthenticationProvider } from './HandleAuthentication';
import { HandleProfilProvider } from './HandleProfil';
import { HandleMagasinMoisAnneeProvider } from './HandleMagasinMoisAnnee';

export default function StartupScriba(props) {
    console.log(`Application:${process.env.REACT_APP_NAME} / version:${process.env.REACT_APP_VERSION}`);
    initializeIcons();

    const lsIsDarkMode = localStorage.getItem('darkModeValue');

    const [versionApi, setVersionApi] = useState('');
    const [versionJs, setVersionJs] = useState(process.env.REACT_APP_VERSION);
    const [isDarkMode, setIsDarkMode] = useState(lsIsDarkMode === 'Dark');
    const [googleCredentials, setGoogleCredentials] = useState({});

    const appTheme = createCustomAppTheme(isDarkMode);

    useEffect(() => {
        getConfiguration();

        if (lsIsDarkMode === null) {
            // Si le th�me n'a jamais �t� s�lectionn�, positionnement automatique du th�me par d�faut selon les param�tres syst�me
            if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                setIsDarkMode(true);
            }
            else {
                setIsDarkMode(false);
            }
        }
        else {
            setIsDarkMode(lsIsDarkMode === 'Dark');
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('darkModeValue', isDarkMode ? 'Dark' : 'Light');
        document.documentElement.setAttribute('data-color-mode', isDarkMode ? 'dark' : 'light');

        if (lsIsDarkMode === null) {
            window.location.reload();
        }
    }, [isDarkMode]);

    return (
        <HandleError>
            <ThemeProvider applyTo="body" theme={appTheme}>
                <ScribaReactContexts.GlobalActions.ScribaGlobalActionsProvider fnReconnexion={() => window.location.reload()}>
                    <HandleAuthenticationProvider credentials={googleCredentials}>
                        <HandleProfilProvider>
                            <HandleMagasinMoisAnneeProvider>
                                {props.children}
                            </HandleMagasinMoisAnneeProvider>
                        </HandleProfilProvider>
                    </HandleAuthenticationProvider>
                </ScribaReactContexts.GlobalActions.ScribaGlobalActionsProvider>
            </ThemeProvider>
        </HandleError>);

    async function getConfiguration() {
        const config = await configApi.get();

        if (config) {
            const json = await config.json();
            setVersionApi(json.backendApiVersion);
            setGoogleCredentials({ clientId: json.clientId, clientSecret: json.clientSecret })
        }

        return null;
    }
}
