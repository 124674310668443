import { useContext } from 'react';
import { HandleAuthenticationContext } from '../ScribaSpecific/HandleAuthentication';
const getLanguageHeader = () => '';

export default function useRayonApi() {
    const { token } = useContext(HandleAuthenticationContext);
    const baseUrl = 'api/rayon';

    const lister = async (codeGroupe) => {
        var url = `${baseUrl}/${codeGroupe}`;
        var parameters = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
                ...await getLanguageHeader()
            },
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    };

    return {
        lister
    }
}