import React from 'react';
import { DefaultButton, PrimaryButton } from '@fluentui/react';
import { ScribaReactContexts } from 'react-scriba-fluentui-web';

const Bouton = (props) => {
    const { value, type, className, onClick, disabled, iconName, scaleDown, withoutLoader } = props;

    const { withLoading } = ScribaReactContexts.GlobalActions.useScribaGlobalActions();

    const onClickAndLoad = (e) => {
        if (e.detail === 1) {
            if (withoutLoader) {
                onClick();
            }
            else {
                withLoading(() => onClick());
            }
        }
    }

    const elements = {
        'Enregistrer': <PrimaryButton className={className || "boutonPanel"} iconProps={{ iconName: 'Add' }} onClick={onClickAndLoad} disabled={disabled || false}>{value || "Enregistrer"}</PrimaryButton>,
        'Supprimer': <PrimaryButton style={{ float: 'right' }} className={className || "boutonPanel"} iconProps={{ iconName: 'Delete' }} onClick={onClick} disabled={disabled || false}>{value || "Supprimer"}</PrimaryButton>,
        'Annuler': <DefaultButton className={className} iconProps={{ iconName: 'Cancel' }} onClick={onClick} disabled={disabled || false}>{value || "Annuler"}</DefaultButton>,
        'Fermer': <DefaultButton className={className} iconProps={{ iconName: 'Cancel' }} onClick={onClick} disabled={disabled || false}>{value || "Fermer"}</DefaultButton>,
        'Retour': <DefaultButton className={className} iconProps={{ iconName: 'Back' }} onClick={onClick} disabled={disabled || false}>{value}</DefaultButton>,
        'Custom': <PrimaryButton className={className || "boutonPanel"} iconProps={{ iconName: iconName }} onClick={onClick} disabled={disabled || false}>{value}</PrimaryButton>,
        'CustomDefault': <DefaultButton className={className} iconProps={{ iconName: iconName }} onClick={onClick} disabled={disabled || false}>{value}</DefaultButton>,
        'Scale': <DefaultButton className={className || "boutonPanel"} style={{ float: scaleDown ? 'right' : 'none' }} iconProps={{ iconName: 'ScaleUp' }} onClick={onClick} disabled={disabled || false}>{scaleDown ? "Réduire" : "Agrandir"}</DefaultButton>
    }

    return elements[type];
}

export default Bouton;