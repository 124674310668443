import { useContext } from 'react';
import { HandleAuthenticationContext } from '../ScribaSpecific/HandleAuthentication';
const getLanguageHeader = () => '';

export default function useSalarieApi() {
    const { token } = useContext(HandleAuthenticationContext);
    const baseUrl = 'api/salarie';

    const lister = async () => {
        var url = `${baseUrl}`;
        var parameters = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
                ...await getLanguageHeader()
            },
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    };

    const listerPrime = async (codeMagasin, annee, mois, idSecteur) => {
        var url = `${baseUrl}/avec-prime?codeMagasin=${codeMagasin}&mois=${mois}&annee=${annee}`;
        if (idSecteur)
            url += `&idSecteur=${idSecteur}`;
        var parameters = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
                ...await getLanguageHeader()
            },
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    };

    const listerAvecResponsable = async (codeMagasin, annee, mois, idSecteur) => {
        var url = `${baseUrl}/avec-responsable?codeMagasin=${codeMagasin}&mois=${mois}&annee=${annee}`;
        if (idSecteur)
            url += `&idSecteur=${idSecteur}`; 
        var parameters = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
                ...await getLanguageHeader()
            },
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    };

    const listerMemeSecteur = async (annee, mois, matricule) => {
        var url = `${baseUrl}/salarie-meme-secteur/${annee}/${mois}/${matricule}`;
        var parameters = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
                ...await getLanguageHeader()
            },
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    };

    const detailler = async (matricule) => {
        var url = `${baseUrl}/${matricule}`;
        var parameters = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
                ...await getLanguageHeader()
            },
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    };

    const detaillerComplet = async (codeMagasin, annee, mois, id) => {
        var url = `${baseUrl}/complet/${codeMagasin}/${annee}/${mois}/${id}`;
        var parameters = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
                ...await getLanguageHeader()
            },
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    };

    const creer = async (item) => {
        var url = `${baseUrl}`;
        var parameters = {
            method: 'POST',
            body: JSON.stringify({ ...item }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                ...await getLanguageHeader()
            },
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    }

    const modifier = async (item) => {
        var url = `${baseUrl}`;
        var parameters = {
            method: 'PUT',
            body: JSON.stringify({ ...item }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                ...await getLanguageHeader()
            },
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    }

    const rechercher = async (filtre) => {
        var url = `${baseUrl}/rechercher`;
        var parameters = {
            method: 'POST',
            body: JSON.stringify(filtre),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                ...await getLanguageHeader()
            },
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    };

    return {
        lister, listerAvecResponsable, listerMemeSecteur, listerPrime, detailler, detaillerComplet, creer, modifier, rechercher
    }
}