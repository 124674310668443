import React, { Fragment, useEffect, useState, useRef } from 'react';
import { DetailsList, CommandBar, SelectionMode, Separator, ComboBox } from '@fluentui/react';
import useFamilleEmploiApi from '../../apis/useFamilleEmploiApi';
import usePrimeApi from '../../apis/usePrimeApi';
import { scriba, ScribaReactContexts } from 'react-scriba-fluentui-web'
import Tools from '../tools';
import RechercheLibre from '../Generique/RechercheLibre';
import { useCallback } from 'react';
import { List } from 'reactstrap';
import { policies } from '../constants';
import AuthorizeComponent from '../Generique/AuthorizeComponent';

const FamilleEmploiListe = (props) => {

    const { contains } = scriba.ScribaOutils;
    const { setErrorMessage, setSuccessMessage, withLoading, getApiResponseJson, getApiResponse } = ScribaReactContexts.GlobalActions.useScribaGlobalActions();
    const familleEmploiApi = useFamilleEmploiApi();
    const primeApi = usePrimeApi();

    const [items, setItems] = useState([]);

    const dataColumns = [
        {
            key: 'CodeFamilleEmploi',
            name: 'Code',
            ariaLabel: 'CodeFamilleEmploi',
            fieldName: 'codeFamilleEmploi',
            minWidth: 200,
            maxWidth: 300,
            onColumnClick: _onColumnClick,
            isResizable: true
        },
        {
            key: 'NomFamilleEmploi',
            name: 'Famille d\'Emploi',
            ariaLabel: 'NomFamilleEmploi',
            fieldName: 'nomFamilleEmploi',
            minWidth: 300,
            maxWidth: 400,
            onColumnClick: _onColumnClick,
            isResizable: true
        },
        {
            key: 'IdPrime',
            name: 'Prime',
            ariaLabel: 'IdPrime',
            minWidth: 50,
            maxWidth: 100,
            onColumnClick: _onColumnClick,
            isResizable: true,
            onRender: (item) => {
                return <ComboBox
                    className="col-4"
                    selectedKey={item.idPrime}
                    options={refPrimes.current}
                    onChange={(e, opt, i, v) => {
                        onEnregistrer({ ...item, idPrime: opt.key })
                    }}
                />
            }
        }
    ];


    const [columns, setColumns] = useState(dataColumns);
    const [rechercheLibreFilter, setRechercheLibreFilter] = useState('');
    const refItems = useRef(items);
    const refPrimes = useRef([]);

    useEffect(() => {
        chargementComboBox();
        withLoading(() => chargementDonnees());
    }, []);


    async function chargementDonnees() {
        const r = await getApiResponseJson(() => familleEmploiApi.lister());

        if (r && !r.estErreur) {
            refItems.current = r;
            setColumns(dataColumns);
            setItems(r);
        }
    }
    async function chargementComboBox() {
        const r = await getApiResponseJson(() => primeApi.lister());

        if (r && !r.estErreur) {
            const primes = [{ key: null, text: 'Sélectionnez' }, ...r.map(x => ({ key: x.idPrime, text: x.nomPrime }))];
            refPrimes.current = primes;
        }
    }

    async function chargementDonneesPartiel(elem) {
        setItems(st => st.map(x => {
            if (x.codeFamilleEmploi == elem.codeFamilleEmploi)
                x.idPrime = elem.idPrime;
            return x;
        }));
    }

    async function onEnregistrer(item) {

        const r = await getApiResponseJson(() => familleEmploiApi.modifier(item));

        if (r && !r.estErreur) {
            setSuccessMessage(`Modification effectuée avec succès.`);
            chargementDonneesPartiel(r);
        }
    }

    const creationContent = () => {
        const itemsFiltered = (!items ? [] : (items.length > 0 ? items.filter(i => contains(rechercheLibreFilter, i.familleEmploi)) : items));

        return (
            <AuthorizeComponent fonctionnalite={policies.administrationFamilleEmploiPrime}>
                <CommandBar
                    items={getCommandBarItems}
                />
                <Separator />
                <RechercheLibre
                    disabled={!items || items.length < 1}
                    value={rechercheLibreFilter}
                    onChange={(e) => setRechercheLibreFilter(e)}
                    customStyles={{ width: 400, height: 40, marginTop: '10px' }}
                />
                <DetailsList
                    setKey="items"
                    items={itemsFiltered}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                    onRenderRow={Tools.onRenderRowLignesAlternees}
                />
            </AuthorizeComponent>)
    }
    function _onColumnClick(ev, column) {
        const sortedValues = Tools.sortColumns(refItems.current, columns, column);
        setItems(sortedValues[0]);
        setColumns(sortedValues[1]);
    }

    const getCommandBarItems = [
        {
            key: 'title',
            onRender: () => <h2>Administration - Gestion des primes affectées à une famille d'emploi</h2>
        }
    ];

    return (creationContent());
}

export default FamilleEmploiListe