import React, { Fragment, useEffect, useState, useContext, useCallback } from 'react';
import { DetailsList, CommandBar, SelectionMode, Separator, ComboBox } from '@fluentui/react';
import useMagasinMoisGereApi from '../../apis/useMagasinMoisGereApi';
import { scriba, ScribaReactContexts } from 'react-scriba-fluentui-web'
import Tools from '../tools';
import { policies } from '../constants';
import AuthorizeComponent from '../Generique/AuthorizeComponent';
import Bouton from '../Generique/Bouton';
import { HandleMagasinMoisAnneeContext } from '../../ScribaSpecific/HandleMagasinMoisAnnee';
import DialogInitialisationDonnees from './DialogInitialisationDonnees';

const dateJour = new Date()
const FamilleEmploiListe = (props) => {

    const { contains } = scriba.ScribaOutils;
    const { setErrorMessage, setSuccessMessage, withLoading, getApiResponseJson, getApiResponse } = ScribaReactContexts.GlobalActions.useScribaGlobalActions();
    const { codeMagasin, mois, annee } = useContext(HandleMagasinMoisAnneeContext);

    const magasinMoisGereApi = useMagasinMoisGereApi();

    const [items, setItems] = useState([]);
    const [afficheDialogInitialisationDonnees, setAfficheDialogInitialisationDonnees] = useState('');
    const [anneeInit, setAnneeInit] = useState(null);
    const [moisInit, setMoisInit] = useState(null);

    useEffect(() => {
        withLoading(() => chargementDonnees(codeMagasin));
    }, [codeMagasin]);


    async function chargementDonnees(magasinCode) {
        const dateInitPossible = new Date(dateJour);
        dateInitPossible.setMonth(dateJour.getMonth() + 2);
        const r = await getApiResponseJson(() => magasinMoisGereApi.lister(magasinCode, dateInitPossible.getFullYear(), dateInitPossible.getMonth()));
        if (r && !r.estErreur) {
            setItems(r);
        }
    }

    const onInitialiser = useCallback(async () => {
        const r = await getApiResponseJson(() => magasinMoisGereApi.initialiser(codeMagasin, anneeInit, moisInit));
        const dateInit = new Date(anneeInit, moisInit-1)
        if (r && !r.estErreur) {
            setSuccessMessage(`Initialisation des données effectuée sur le magasin ${codeMagasin} pour ${scriba.ScribaDate.stringDateFormat(dateInit, 'MMMM/YYYY') }. ${r}`);
            chargementDonnees(codeMagasin);
        }
    }, [codeMagasin, anneeInit, moisInit])

    function onAfficheDialogInitialisationDonnees(an, m) {
        setAnneeInit(an)
        setMoisInit(m)
        setAfficheDialogInitialisationDonnees(true)
    }

    function onDissimuleDialogInitialisationDonnees() {
        setAnneeInit(null)
        setMoisInit(null)
        setAfficheDialogInitialisationDonnees(false)
    }

    const creationContent = () => {
        return (
            <AuthorizeComponent fonctionnalite={policies.magasinInitialisationDesDonnees}>
                <h2>Magasin - Initialiser les données</h2>
                <DetailsList
                    setKey="items"
                    items={items}
                    columns={[...dataColumns, ...actionColumns]}
                    selectionMode={SelectionMode.none}
                    onRenderRow={Tools.onRenderRowLignesAlternees}
                />
                <DialogInitialisationDonnees
                    codeMagasin={codeMagasin}
                    mois={moisInit}
                    annee={anneeInit}
                    visible={afficheDialogInitialisationDonnees}
                    onCancel={() => onDissimuleDialogInitialisationDonnees()}
                    onOk={ () => onInitialiser()}
                />
            </AuthorizeComponent>)
    }

    const dataColumns = [
        {
            key: 'Annee',
            name: 'Année',
            ariaLabel: 'Annee',
            fieldName: 'annee',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true
        }, {
            key: 'Mois',
            name: 'Mois',
            ariaLabel: 'Mois',
            fieldName: 'mois',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender: item => {
                const dJour = new Date(dateJour);
                dJour.setMonth(item.mois - 1);
                return scriba.ScribaDate.stringDateFormat(dJour, 'MMMM')
            }
        }, {
            key: 'Objectif',
            name: 'Objectif',
            ariaLabel: 'Objectif',
            fieldName: 'caObjectif',
            className: 'celluleMonnaie',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            onRender: item => !item.caObjectif ? '-' : Tools.formatageNombreSeparateurMilliersAvecSigleMonetaire(item.caObjectif)
        }, {
            key: 'Realise',
            name: 'Réalisé',
            ariaLabel: 'Realise',
            fieldName: 'caRealise',
            className: 'celluleMonnaie',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            onRender: item => !item.caRealise ? '-' : Tools.formatageNombreSeparateurMilliersAvecSigleMonetaire(item.caRealise)
        }
    ];

    const actionColumns = [
        {
            key: 'Initialiser',
            name: '',
            ariaLabel: 'Initialiser',
            minWidth: 150,
            maxWidth: 200,
            isResizable: true,
            onRender: (item) => {
                return <Bouton type='Custom' value='Initialiser' onClick={() => onAfficheDialogInitialisationDonnees(item.annee, item.mois)} disabled={item.estDejaInitialise} />
            }
        }
    ];

    const getCommandBarItems = [];

    return (creationContent());
}

export default FamilleEmploiListe