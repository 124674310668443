import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ComboBox, DefaultButton, Label, Modal, PrimaryButton, SelectableOptionMenuItemType } from '@fluentui/react';
import { ScribaFluentUiComposants, ScribaReactContexts } from 'react-scriba-fluentui-web';
import useSalarieApi from '../../apis/useSalarieApi';
import { HandleMagasinMoisAnneeContext } from '../../ScribaSpecific/HandleMagasinMoisAnnee';

export default function DialogCopieSalarie(props) {
    const {
        matricule,
        visible,
        onCancel,
        onOk
    } = props;

    const { mois, annee } = useContext(HandleMagasinMoisAnneeContext);
    const { getApiResponseJson } = ScribaReactContexts.GlobalActions.useScribaGlobalActions();
    const salarieApi = useSalarieApi();

    const [matriculeReferenceCopie, setMatriculeReferenceCopie] = useState(null);
    const [salaries, setSalaries] = useState([]);

    useEffect(() => {
        if (visible && matricule && annee && mois)
            chargementSalarieACopier(annee, mois, matricule);

        return () => {
            setMatriculeReferenceCopie(null);
        }
    }, [annee, mois, matricule, visible]);

    async function chargementSalarieACopier(anne, moi, mat) {
        const r = await getApiResponseJson(() => salarieApi.listerMemeSecteur(anne, moi, mat));
        if (r && !r.estErreur) {
            setSalaries(r);
        }
    }

    async function lancerLaCopie() {
        await onOk(matriculeReferenceCopie);
        onCancel();
    }

    const salarieOptions = useMemo(() => {
        const salarieAvecSpi = salaries.filter(s => s.nbSalariePerimetreIndividuel > 0).map(s => ({ key: s.matricule, text: s.nomSalarie }));
        const salarieSansSpi = salaries.filter(s => s.nbSalariePerimetreIndividuel < 1).map(s => ({ key: s.matricule, text: s.nomSalarie }))
        return [
            { key: 'header1', text: `Avec périmètre individuel (${salarieAvecSpi.length})`, itemType: SelectableOptionMenuItemType.Header },
            ...salarieAvecSpi,
            { key: 'header2', text: `Sans périmètre individuel (${salarieSansSpi.length})`, itemType: SelectableOptionMenuItemType.Header },
            ...salarieSansSpi
        ]
    }, [salaries]);

    return <Modal
        isOpen={visible}
        onDismiss={onCancel}
        isBlocking={true}
        allowTouchBodyScroll={true}
        styles={{ main: { maxWidth: 500, padding: '1rem' } }}
    >
        <div>
            <h3>Copie d'un salarié : </h3>
        </div>
        <div style={{ marginBottom: '1rem' }}>
            <span>Sélectionner le salarié de même secteur</span>
        </div>
     <ComboBox
            label="Salarié de référence"
            placeholder="Sélectionner un salarié..."
            selectedKey={matriculeReferenceCopie}
            options={salarieOptions}
            onChange={(e, opt, i, v) => setMatriculeReferenceCopie(opt.key)}
        />
        <div style={{ marginTop: '1rem' }}>
            <PrimaryButton disabled={!matriculeReferenceCopie} onClick={() => lancerLaCopie() } text="Copier" />
            <DefaultButton onClick={onCancel} text="Annuler" />
        </div>
    </Modal>;
}