import React from 'react';
import { scriba } from 'react-scriba-fluentui-web'

export default class Tools {

    static sortColumns = (items, columns, column) => {
        const newColumns = columns.slice();
        const currColumn = newColumns.filter(currCol => column.key === currCol.key)[0];

        newColumns.forEach((newCol) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            }
            else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });

        const newItems = Tools._copyAndSort(items, currColumn.fieldName, currColumn.isSortedDescending);

        return [newItems, newColumns];
    };

    static _copyAndSort(items, columnKey, isSortedDescending) {
        const sortedItems = items.slice(0).sort((a, b) => {
            const colKeyA = a[columnKey];
            const colKeyB = b[columnKey];

            if (typeof colKeyA == 'number' || typeof colKeyA == 'boolean') {
                return (isSortedDescending ? colKeyA < colKeyB : colKeyA > colKeyB) ? 1 : -1;
            }
            else {
                // Valeurs null au début par ordre croissant, à la fin par ordre décroissant
                if (colKeyA === null) {
                    return isSortedDescending ? 1 : -1;
                }

                if (colKeyB === null) {
                    return isSortedDescending ? -1 : 1;
                }

                return (isSortedDescending ? colKeyB?.localeCompare(colKeyA) : colKeyA?.localeCompare(colKeyB));
            }
        });

        return sortedItems
    }

    static parseJwt(tokenBrut) {
        if (!tokenBrut || tokenBrut.length < 1)
            return {};

        var base64Url = tokenBrut.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    static formatageNombreSeparateurMilliers(valeur) {
        if (valeur === null ||Number.isNaN(valeur))
            return ''
        return valeur && scriba.ScribaNombre.nombreVersTexte(valeur, true, 2);
    }
    static formatageNombreSeparateurMilliersAvecSigleMonetaire(valeur) {
        if (valeur ===null || Number.isNaN(valeur))
            return ''
        return `${scriba.ScribaNombre.nombreVersTexte(valeur, true, 2)} €`;
    }
}