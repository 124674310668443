import React, { Fragment, useEffect, useState, useContext } from 'react';
import { DetailsList, PrimaryButton, SelectionMode, Separator } from '@fluentui/react';
import useVenteMagasinFamilleApi from '../../apis/useVenteMagasinFamilleApi';
import usePrimeApi from '../../apis/usePrimeApi';
import { scriba, ScribaReactContexts } from 'react-scriba-fluentui-web'
import Tools from '../tools';
import { HandleMagasinMoisAnneeContext } from '../../ScribaSpecific/HandleMagasinMoisAnnee'
import { policies } from '../constants';
import AuthorizeComponent from '../Generique/AuthorizeComponent';

const PrimeCalculFiche = (props) => {

    const { setSuccessMessage, withLoading, getApiResponseJson, getApiResponse } = ScribaReactContexts.GlobalActions.useScribaGlobalActions();
    const venteMagasinFamilleApi = useVenteMagasinFamilleApi();
    const primeApi = usePrimeApi();
    const { codeMagasin, mois, annee } = useContext(HandleMagasinMoisAnneeContext);
    const [anneePrime, setAnneePrime] = useState(0);
    const [moisPrime, setMoisPrime] = useState(0);
    const [moisPrimeLibelle, setMoisPrimeLibelle] = useState(null);

    const dataColumns = [
        {
            key: 'NomMagasin',
            name: 'Magasin',
            ariaLabel: 'NomMagasin',
            fieldName: 'nomMagasin',
            minWidth: 150,
            maxWidth: 250,
            isResizable: true
        },
        {
            key: 'CAObjectif',
            name: 'Objectif',
            ariaLabel: 'CAObjectif',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender: (item) => {
                return Tools.formatageNombreSeparateurMilliersAvecSigleMonetaire(item.caObjectif);
            }
        },
        {
            key: 'CARestantAVentiler',
            name: 'Restant à ventiler',
            ariaLabel: 'CARestantAVentiler',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender: (item) => {
                return Tools.formatageNombreSeparateurMilliersAvecSigleMonetaire(item.caRestantAVentiler);
            }
        }
    ];

    const [items, setItems] = useState([]);

    useEffect(() => {
        withLoading(() => chargementDonnees());
    }, [anneePrime, moisPrime]);

    useEffect(() => {
        var anneeMois = new Date(annee, mois);
        anneeMois.setMonth(anneeMois.getMonth() - 2);
        setAnneePrime(anneeMois.getFullYear());
        setMoisPrime(anneeMois.getMonth() + 1);
        setMoisPrimeLibelle(scriba.ScribaDate.stringDateFormat(anneeMois, 'MMMM'));
    }, [mois, annee]);

    async function chargementDonnees() {
        const r = await getApiResponseJson(() => venteMagasinFamilleApi.listerMagasinAvecCARestantAVentiler(moisPrime, anneePrime));

        if (r && !r.estErreur) {
            setItems(r);
        }
    }

    async function onEnregistrement(item) {
        const r = await getApiResponse(() => primeApi.calculerPrime(moisPrime, anneePrime));

        if (r.ok) {
            setSuccessMessage(`La prime a bien été enregistrée.`);
        }
    }

    const creationContent = () => {
        return (
            <AuthorizeComponent fonctionnalite={policies.administrationCalculPrime}>
                <h2>Administration - Calcul des primes</h2>
                <Separator />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', margin: '1rem' }}>
                    <div style={{ marginRight: '40px', fontSize: '15px' }}>
                        <span style={{ marginRight: '10px' }}>Période :</span>
                        <span style={{ fontWeight: 'bold' }}>{`${moisPrimeLibelle} ${anneePrime}`}</span>
                    </div>
                    <PrimaryButton disabled={items.length > 0} onClick={() => onEnregistrement()}>Calculer la prime</PrimaryButton>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', margin: '1rem', marginTop: '60px', fontSize: '15px' }}>
                    Liste des magasins dont le CA n'a pas été entièrement réparti :
                </div>
                <DetailsList
                    setKey="items"
                    items={items}
                    columns={dataColumns}
                    selectionMode={SelectionMode.none}
                    onRenderRow={Tools.onRenderRowLignesAlternees}
                />
            </AuthorizeComponent >)
    }

    return (creationContent());
}
/**/
export default PrimeCalculFiche