import React, { Fragment, useEffect, useState, useContext, useMemo } from 'react';
import { DetailsList, IconButton, CommandBar, SelectionMode, Separator, Checkbox, ComboBox, Label, Link, Modal, Toggle, TextField } from '@fluentui/react';
import useVenteMagasinFamilleApi from '../../apis/useVenteMagasinFamilleApi';
import useGroupeApi from '../../apis/useGroupeApi';
import { scriba, ScribaReactContexts, ScribaFluentUiComposants } from 'react-scriba-fluentui-web'
import Tools from '../tools';
import { HandleMagasinMoisAnneeContext } from '../../ScribaSpecific/HandleMagasinMoisAnnee'
import { policies } from '../constants';
import AuthorizeComponent from '../Generique/AuthorizeComponent';

const RepartitionCAListe = (props) => {

    const { contains } = scriba.ScribaOutils;
    const { TextFieldNombre } = ScribaFluentUiComposants;
    const { setErrorMessage, setSuccessMessage, withLoading, getApiResponseJson, getApiResponse } = ScribaReactContexts.GlobalActions.useScribaGlobalActions();
    const venteMagasinFamilleApi = useVenteMagasinFamilleApi();
    const groupeApi = useGroupeApi();
    const { codeMagasin, mois, annee } = useContext(HandleMagasinMoisAnneeContext);
    const dateActuelle = new Date();
    dateActuelle.setDate(1);
    const dateSelection = new Date(annee, mois, 15);

    const dataColumns = [
        {
            key: 'NomGroupe',
            name: 'Groupe',
            ariaLabel: 'NomGroupe',
            fieldName: 'nomGroupe',
            minWidth: 200,
            maxWidth: 300,
            onColumnClick: _onColumnClick,
            isResizable: true
        },
        {
            key: 'NomRayon',
            name: 'Rayon',
            ariaLabel: 'NomRayon',
            fieldName: 'nomRayon',
            minWidth: 200,
            maxWidth: 300,
            onColumnClick: _onColumnClick,
            isResizable: true
        },
        {
            key: 'NomFamille',
            name: 'Famille',
            ariaLabel: 'NomFamille',
            fieldName: 'nomFamille',
            minWidth: 200,
            onColumnClick: _onColumnClick,
            isResizable: true
        },
        {
            key: 'CARealiseNMoinsUn',
            name: 'Réalisé N-1',
            ariaLabel: 'CARealiseNMoinsUn',
            fieldName: 'caRealiseNMoinsUn',
            minWidth: 200,
            maxWidth: 300,
            onColumnClick: _onColumnClick,
            isResizable: true,
            onRender: (item) => {
                return Tools.formatageNombreSeparateurMilliersAvecSigleMonetaire(item.caRealiseNMoinsUn);
            }
        },
        {
            key: 'CAObjectif',
            name: 'Objectif',
            ariaLabel: 'CAObjectif',
            fieldName: 'cAObjectif',
            minWidth: 200,
            maxWidth: 300,
            onColumnClick: _onColumnClick,
            isResizable: true,
            onRender: (item) => {
                return (dateActuelle <= dateSelection)
                    ? <TextFieldNombre
                        valeur={item.caObjectif}
                        setValeur={val => {
                            const objectif = !val ? 0 : parseFloat(val).toFixed(2)
                            rechargementObjectif({ ...item, caObjectif: objectif })
                        }}
                        suffix='€'
                        precision={2}
                    />
                    : Tools.formatageNombreSeparateurMilliersAvecSigleMonetaire(item.caObjectif);
            }
        },
        {
            key: 'Indice',
            name: 'Indice (%)',
            ariaLabel: 'Indice',
            fieldName: 'indice',
            minWidth: 200,
            maxWidth: 300,
            onColumnClick: _onColumnClick,
            isResizable: true,
            onRender: (item) => {
                return (dateActuelle <= dateSelection && item.caRealiseNMoinsUn)
                    ? (item.caRealiseNMoinsUn >= 0
                        ? <TextFieldNombre
                            valeur={item.indice}
                            setValeur={val => {
                                const indice = !val ? 0 : parseFloat(val).toFixed(2)
                                rechargementObjectifParCalculIndice({ ...item, indice })
                            }}
                            suffix='%'
                            precision={2}
                        />
                        : null)
                    : Tools.formatageNombreSeparateurMilliersAvecSigleMonetaire(item.indice);
            }
        }
    ];

    const [items, setItems] = useState([]);
    const [columns, setColumns] = useState(dataColumns);
    const [groupes, setGroupes] = useState([]);
    const [codeGroupe, setCodeGroupe] = useState(null);
    const [caTotal, setCaTotal] = useState(null);
    const [caVentile, setCaVentile] = useState(null);
    const [caRestantAVentile, setCaRestantAVentile] = useState(null);

    useEffect(() => {
        if (codeMagasin && mois && annee)
            chargementComboBox();
    }, [codeMagasin, mois, annee]);

    useEffect(() => {
        if (codeMagasin && mois && annee)
            withLoading(() => chargementDonnees());
    }, [codeMagasin, mois, annee, codeGroupe]);

    useEffect(() => {
        const caTotal = codeGroupe ?
            groupes.find((e) => e.codeGroupe == codeGroupe).caObjectif :
            groupes.reduce((accumulator, currentValue) => accumulator + (currentValue.caObjectif ? parseFloat(currentValue.caObjectif) : 0), 0).toFixed(2);

        setCaTotal(caTotal && parseFloat(caTotal).toFixed(2));
    }, [groupes, codeGroupe]);

    useEffect(() => {
        if (caTotal) {
            const caVentile = calculerVentile(items);
            setCaVentile(caVentile);
            const caRestantAVentile = (caTotal - caVentile).toFixed(2);
            setCaRestantAVentile(caRestantAVentile);
        } else {
            setCaVentile(null);
            setCaRestantAVentile(null);
        }
    }, [items]);

    async function chargementDonnees() {
        const r = await getApiResponseJson(() => venteMagasinFamilleApi.listerRepartitionCA(codeMagasin, mois, annee, codeGroupe));
        if (r && !r.estErreur) {
            setItems(r);
        }
    }
    async function chargementComboBox() {
        const r = await getApiResponseJson(() => groupeApi.listerParMagasin(codeMagasin, mois, annee));
        if (r && !r.estErreur) {
            setGroupes(r);
        }
    }

    async function rechargementObjectif(item) {
        const indice = item.caObjectif && item.caRealiseNMoinsUn ? (item.caObjectif / item.caRealiseNMoinsUn * 100).toFixed(2) : null;
        onEnregistrement({ ...item, indice })
    }
    async function rechargementObjectifParCalculIndice(item) {
        const objectif = (item.caRealiseNMoinsUn * (item.indice / 100)).toFixed(2);
        onEnregistrement({ ...item, caObjectif: objectif })
    }

    async function onEnregistrement(item) {
        const r = await getApiResponseJson(() => venteMagasinFamilleApi.enregistrer({ codeMagasin: codeMagasin, codeFamille: item.codeFamille, mois: mois, annee: annee, caRealise: item.caRealise, caObjectif: item.caObjectif }));
        if (r && !r.estErreur) {
            let ventile = 0;
            setItems(st => {
                const newState = st.map(x => x.codeFamille === item.codeFamille ? item : x)
                ventile = calculerVentile(newState);
                return newState;
            })
            const restant = caTotal - ventile;
            setSuccessMessage(`L'élément a été modifié avec succès. Chiffre d'affaire restant à ventiler : ${Tools.formatageNombreSeparateurMilliersAvecSigleMonetaire(restant)}`);
        }
    }

    function calculerVentile(liste) {
        return liste.reduce((accumulator, currentValue) => accumulator + (currentValue.caObjectif ? parseFloat(currentValue.caObjectif) : 0), 0).toFixed(2)
    }

    const groupesOptions = useMemo(() => [{ key: null, text: 'Sélectionnez un groupe' }, ...groupes.map((e) => ({ key: e.codeGroupe, text: e.nomGroupe }))], [groupes]);

    const creationContent = () => {
        return (
            <AuthorizeComponent fonctionnalite={policies.magasinRepartitionCa}>
                <h2>Magasin - Répartition CA</h2>
                <Separator />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', margin: '1rem' }}>
                    <ComboBox
                        label="Groupe"
                        className="col-2"
                        selectedKey={codeGroupe}
                        options={groupesOptions}
                        onChange={(e, opt, i, v) => {
                            setCodeGroupe(opt.key)
                        }}
                    />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', margin: '1rem' }}>
                    <div style={{ fontSize: '14px', fontWeight: 'bold' }} className="col-3">
                        <span style={{ textDecoration: 'underline' }}>Chiffre d'affaire total</span> : {caTotal ? Tools.formatageNombreSeparateurMilliersAvecSigleMonetaire(caTotal) : 'Non renseigné'}
                    </div>
                    <div style={{ fontSize: '14px', fontWeight: 'bold' }} className="col-3">
                        <span style={{ textDecoration: 'underline' }}>Chiffre d'affaire ventilé</span> : {caVentile ? Tools.formatageNombreSeparateurMilliersAvecSigleMonetaire(caVentile) : 'Non calculable'}
                    </div>
                    <div style={{ fontSize: '14px', fontWeight: 'bold' }} className="col-3">
                        <span style={{ textDecoration: 'underline' }}>Chiffre d'affaire restant à ventiler</span> : {caRestantAVentile ? Tools.formatageNombreSeparateurMilliersAvecSigleMonetaire(caRestantAVentile) : 'Non calculable'}
                    </div>
                </div>
                <DetailsList
                    setKey="items"
                    items={items}
                    columns={[...dataColumns, ...actionColumns]}
                    selectionMode={SelectionMode.none}
                    onRenderRow={Tools.onRenderRowLignesAlternees}
                />
            </AuthorizeComponent >)
    }

    const actionColumns = [
        {
            key: 'modifier',
            name: '',
            minWidth: 20
        }
    ]

    function _onColumnClick(ev, column) {
        const sortedValues = Tools.sortColumns(items, columns, column);
        setItems(sortedValues[0]);
        setColumns(sortedValues[1]);
    }

    return (creationContent());
}

export default RepartitionCAListe