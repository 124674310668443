import React, { Component } from 'react';
import useProfil from '../../ScribaSpecific/useProfil';

export default function AuthorizeComponent(props) {
    const {
        fonctionnalite,
        listeFonctionnalite,
        children
    } = props;
    const { peutAcceder, policies } = useProfil();
    let autorise = true;
    if (fonctionnalite)
        autorise &= peutAcceder(fonctionnalite);

    if (listeFonctionnalite && Array.isArray(listeFonctionnalite)) {
        autorise &= listeFonctionnalite.reduce((acc, f) => {
            acc &= peutAcceder(f)
            return acc;
        }, autorise)
    }

    return (autorise && children);
}