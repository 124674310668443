import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { DefaultButton, PrimaryButton } from '@fluentui/react';
import { scriba, ScribaFluentUiComposants, ScribaReactContexts } from 'react-scriba-fluentui-web'
import { HandleAuthenticationContext } from './HandleAuthentication';
import useAuthApi from '../apis/useAuthApi';
import Tools from '../components/tools';

import { policies } from '../components/constants'

const HandleProfilContext = React.createContext([{}, { }]);

const HandleProfilProvider = (props) => {
    const { withLoading, getApiResponseJson, setErrorMessage } = ScribaReactContexts.GlobalActions.useScribaGlobalActions();
    const { isAuthenticated, token, logOut } = useContext(HandleAuthenticationContext);

    const defaultPersonne = {
        idPersonne: '',
        email: '',
        administrateurGlobal: '',
        matricule: '',
        droits: [],
        isAuthenticated: false
    }

    const authApi = useAuthApi();
    const [profil, setProfil] = useState(defaultPersonne);
    const [profilIntrouvable, setProfilIntrouvable] = useState(true);
    const [chargementProfilEnCours, setChargementProfilEnCours] = useState(true);
    const [personalData, setPersonalData] = useState(null);

    const email = useMemo(() => !personalData ? '' : personalData.email, [personalData])

    useEffect(() => {
        if (!isAuthenticated || !email) {
            console.log('Chargement du profil par défaut');
            setProfil(defaultPersonne)
        }
        else {
            withLoading(() => loadUser(email), 'Récupération du profil utilisateur...');
        }
        return () => setProfilIntrouvable(false);
    }, [isAuthenticated, email]);

    useEffect(() => {
        if (!token)
            return;

        const { email, sub, name, given_name, family_name, picture } = Tools.parseJwt(token);
        setPersonalData({
            email: email,
            nomComplet: name,
            prenom: given_name,
            nom: family_name,
            imageUrl: picture
        })
    }, [token])

    /**
     * Chargement du profil utilisateur 
     * @param {any} email
     */
    async function loadUser(email) {
        setChargementProfilEnCours(true);       
        // Charger les infos utilisateurs, language, droits...
        const result = await getApiResponseJson(() => authApi.get(email));
        if (result && !result.estErreur) {
            console.log('Chargement du profil');
            const { personneDto, droits, policyNames } = result;
            setProfil(previousSt => ({
                ...personneDto,
                droits: droits,
                policies: policies,
                isAuthenticated
            }));

            init(policyNames)
        }
        else {
            setProfilIntrouvable(true);
        }

        setChargementProfilEnCours(false);
    }


    

    function init(policyNames) {
        // match policies between frontend and backend
        console.log('polices', policies, policyNames)
        const nonManquante = true;//policies.some(p => preDefinePolicies.some(pp => pp === p))
        if (!nonManquante)
            throw Error("Les entrées de polices diffèrent entre le front et le back. Veuillez corriger le problème en comparant ces deux listes");
    }

    function profilIntrouvableMessage(email) {
        return (
            <div className="d-flex flex-column align-items-center justify-content-center vh-100">
                <div className="my-1">Profil introuvable ({email})</div>
                <div className="my-1">Veuillez contacter un administrateur afin d'ajouter votre profil dans le référentiel utilisateur</div>
                <div className="my-1">
                    <PrimaryButton className={"boutonPanel"} onClick={() => { logOut() }}>Se déconnecter</PrimaryButton>
                </div>
            </div>);
    }

    return (
        <HandleProfilContext.Provider value={[profil, personalData]}>
            {profilIntrouvable && profilIntrouvableMessage(email)}
            {!chargementProfilEnCours && isAuthenticated && !profilIntrouvable && props.children}
        </HandleProfilContext.Provider>
    );
}

export { HandleProfilContext, HandleProfilProvider };