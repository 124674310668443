import React, { useState, useContext } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontIcon, IconButton, Persona, PersonaSize } from '@fluentui/react';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { useTheme } from '@fluentui/react';
import { HandleAuthenticationContext } from '../ScribaSpecific/HandleAuthentication';
import { HandleProfilContext } from '../ScribaSpecific/HandleProfil';
import SelectionMagasinMoisAnnee from './Generique/SelectionMagasinMoisAnnee';
import AuthorizeComponent from './Generique/AuthorizeComponent';
import { policies } from './constants';


export function NavMenu(props) {
    const { } = props;

    const { logOut } = useContext(HandleAuthenticationContext);
    const [profil, personalData] = useContext(HandleProfilContext);
    const theme = useTheme();

    const [collapsed, setCollapsed] = useState(true);

    function creationContent() {
        const estDarkMode = localStorage.getItem('darkModeValue') === 'Dark';
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3 navbarCustom" container dark>
                    <NavbarBrand tag={Link} to="/">REMV TPR</NavbarBrand>
                    <span className="numVersion">{process.env.REACT_APP_VERSION}</span>
                    <NavbarToggler onClick={() => setCollapsed(!collapsed)} className="mr-2" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
                        <ul className="navbar-nav flex-grow">
                            <NavItem>
                                <SelectionMagasinMoisAnnee idPersonne={profil.idPersonne} />
                            </NavItem>
                            <AuthorizeComponent fonctionnalite={policies.salarieConsultation} >
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/salariesPrime">Salariés</NavLink>
                                </NavItem>
                            </AuthorizeComponent>
                            <AuthorizeComponent listeFonctionnalite={[policies.magasinAccelerateurPrime, policies.magasinFamilleSecteur, policies.magasinInitialisationDesDonnees, policies.magasinRepartitionCa, policies.magasinSalariePerimetreIndividuel]}>
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav className="text-dark">
                                        <FontIcon aria-label="Magasin" iconName="Settings" />Magasin
                                    </DropdownToggle>
                                    <DropdownMenu style={{ backgroundColor: theme.palette.white }}>
                                        <AuthorizeComponent fonctionnalite={policies.magasinInitialisationDesDonnees}>
                                            <DropdownItem><Link style={{ color: theme.palette.black }} to="/initialisationDonnees">Initialisation</Link></DropdownItem>
                                        </AuthorizeComponent>
                                        <AuthorizeComponent fonctionnalite={policies.magasinFamilleSecteur}>
                                            <DropdownItem><Link style={{ color: theme.palette.black }} to="/familleSecteur">Familles / secteur</Link></DropdownItem>
                                        </AuthorizeComponent>
                                        <AuthorizeComponent fonctionnalite={policies.magasinSalariePerimetreIndividuel}>
                                            <DropdownItem><Link style={{ color: theme.palette.black }} to="/salariePerimetre">Périmètre individuel</Link></DropdownItem>
                                        </AuthorizeComponent>
                                        <AuthorizeComponent fonctionnalite={policies.magasinRepartitionCa}>
                                            <DropdownItem><Link style={{ color: theme.palette.black }} to="/repartitionCa">Répartition CA</Link></DropdownItem>
                                        </AuthorizeComponent>
                                        <AuthorizeComponent fonctionnalite={policies.magasinAccelerateurPrime}>
                                            <DropdownItem><Link style={{ color: theme.palette.black }} to="/accelerateurPrime">Accélérateur de primes</Link></DropdownItem>
                                        </AuthorizeComponent>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </AuthorizeComponent>
                            <AuthorizeComponent listeFonctionnalite={[policies.administrationUtilisateur, policies.administrationFamilleEmploiPrime, policies.administrationCalculPrime]} >
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav className="text-dark">
                                        <FontIcon aria-label="Administration" iconName="Settings" />Administration
                                    </DropdownToggle>
                                    <DropdownMenu style={{ backgroundColor: theme.palette.white }}>
                                        <AuthorizeComponent fonctionnalite={policies.administrationUtilisateur}>
                                            <DropdownItem><Link style={{ color: theme.palette.black }} to="/personne">Utilisateurs</Link></DropdownItem>
                                        </AuthorizeComponent>
                                        <AuthorizeComponent fonctionnalite={policies.administrationFamilleEmploiPrime}>
                                            <DropdownItem><Link style={{ color: theme.palette.black }} to="/familleEmploi">Famille d'emp. / prime</Link></DropdownItem>
                                        </AuthorizeComponent>
                                        <AuthorizeComponent fonctionnalite={policies.administrationCalculPrime}>
                                            <DropdownItem><Link style={{ color: theme.palette.black }} to="/calculPrime">Calcul des primes</Link></DropdownItem>
                                        </AuthorizeComponent>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </AuthorizeComponent>
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav className="text-dark">
                                    {personalData && <Persona
                                        text={personalData.nomComplet}
                                        secondaryText={personalData.email}
                                        imageUrl={personalData.imageUrl}
                                        size={PersonaSize.size48}
                                    />}
                                </DropdownToggle>
                                <DropdownMenu dark={estDarkMode} style={{ backgroundColor: theme.palette.white }}>
                                    <DropdownItem onClick={logOut} style={{ color: theme.palette.black }}>Se déconnecter</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </ul>
                    </Collapse>
                </Navbar>
            </header>
        );
    }

    return creationContent();
}
