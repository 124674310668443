import { useContext } from 'react';
import { HandleAuthenticationContext } from '../ScribaSpecific/HandleAuthentication';
const getLanguageHeader = () => '';

export default function useSalariePerimetreIndividuelApi() {
    const { token } = useContext(HandleAuthenticationContext);
    const baseUrl = 'api/salarieperimetreindividuel';

    const listerParSalarie = async (codeMagasin, annee, mois,matricule) => {
        var url = `${baseUrl}/${codeMagasin}/${annee}/${mois}/${matricule}`;
        var parameters = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
                ...await getLanguageHeader()
            },
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    };

    const creer = async (item) => {
        var url = `${baseUrl}`;
        var parameters = {
            method: 'POST',
            body: JSON.stringify({ ...item }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                ...await getLanguageHeader()
            },
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    }

    const supprimer = async (codeMagasin, annee, mois, matricule, codeFamille) => {
        var url = `${baseUrl}/${codeMagasin}/${annee}/${mois}/${matricule}/${codeFamille}`;
        var parameters = {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                ...await getLanguageHeader()
            },
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    }

    const copier = async (item) => {
        var url = `${baseUrl}/copier`;
        var parameters = {
            method: 'POST',
            body: JSON.stringify({ ...item }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                ...await getLanguageHeader()
            },
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    }

    return {
        listerParSalarie, creer, supprimer, copier
    }
}