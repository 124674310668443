import React, { Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { DetailsList, IconButton, CommandBar, SelectionMode, Separator, ComboBox, Label } from '@fluentui/react';
import useSalariePerimetreIndividuelApi from '../../apis/useSalariePerimetreIndividuelApi';
import useGroupeApi from '../../apis/useGroupeApi';
import useRayonApi from '../../apis/useRayonApi';
import useFamilleApi from '../../apis/useFamilleApi';
import { scriba, ScribaReactContexts, ScribaFluentUiComposants } from 'react-scriba-fluentui-web'
import Tools from '../tools';
import { HandleMagasinMoisAnneeContext } from '../../ScribaSpecific/HandleMagasinMoisAnnee';
import DialogCopieSalarie from './DialogCopieSalarie';

const PerimetreIndividuelListe = (props) => {
    const { matricule, possedeSecteur } = props;
    const { contains } = scriba.ScribaOutils;
    const { DialogOkCancel } = ScribaFluentUiComposants;
    const { setWarningMessage, setSuccessMessage, withLoading, getApiResponseJson, getApiResponse } = ScribaReactContexts.GlobalActions.useScribaGlobalActions();
    const { codeMagasin, mois, annee } = useContext(HandleMagasinMoisAnneeContext);
    const salariePerimetreIndividuelApi = useSalariePerimetreIndividuelApi();
    const groupeApi = useGroupeApi();
    const rayonApi = useRayonApi();
    const familleApi = useFamilleApi();

    const dataColumns = [
        {
            key: 'NomGroupe',
            name: 'Groupe',
            ariaLabel: 'NomGroupe',
            fieldName: 'nomGroupe',
            minWidth: 100,
            maxWidth: 100,
            onColumnClick: _onColumnClick,
            isResizable: true
        },
        {
            key: 'NomRayon',
            name: 'Rayon',
            ariaLabel: 'NomRayon',
            fieldName: 'nomRayon',
            minWidth: 200,
            onColumnClick: _onColumnClick,
            isResizable: true
        },
        {
            key: 'NomFamille',
            name: 'Famille',
            ariaLabel: 'NomFamille',
            fieldName: 'nomFamille',
            minWidth: 200,
            maxWidth: 300,
            onColumnClick: _onColumnClick,
            isResizable: true
        },
        {
            key: 'EstDemontre',
            name: 'Est démontré',
            ariaLabel: 'EstDemontre',
            fieldName: 'estDemontre',
            minWidth: 100,
            maxWidth: 200,
            onColumnClick: _onColumnClick,
            isResizable: true,
            onRender: (item) => {
                return item.estDemontre ? "Oui" : "Non";
            }
        }
    ];

    const [items, setItems] = useState([]);
    const [columns, setColumns] = useState(dataColumns);
    const [dialogSuppressionVisible, setDialogSuppressionVisible] = useState(false);
    const [rechercheLibreFilter, setRechercheLibreFilter] = useState('');
    const refItems = useRef(items);

    const [groupes, setGroupes] = useState([]);
    const [rayons, setRayons] = useState([]);
    const [familles, setFamilles] = useState([]);
    const [codeFamilleSuppression, setCodeFamilleSuppression] = useState(null);
    const [codeGroupe, setCodeGroupe] = useState(null);
    const [codeRayon, setCodeRayon] = useState(null);
    const [codeFamille, setCodeFamille] = useState(null);
    const [dialogCopieSalarieVisible, setDialogCopieSalarieVisible] = useState(null);

    useEffect(() => {
        chargementDonneesGroupe();
    }, []);

    useEffect(() => {
        if (codeGroupe)
            chargementDonneesRayon(codeGroupe);
        else
            setRayons([]);
    }, [codeGroupe]);

    useEffect(() => {
        if (codeRayon)
            chargementDonneesFamille(codeRayon, matricule);
        else
            setFamilles([]);
    }, [codeGroupe, codeRayon, matricule]);

    useEffect(() => {
        if (matricule)
            chargementDonnees(codeMagasin, annee, mois, matricule)
    }, [mois, annee, matricule]);


    async function chargementDonnees(codeMagasin, annee, mois, id) {
        const r = await getApiResponseJson(() => salariePerimetreIndividuelApi.listerParSalarie(codeMagasin, annee, mois, id));
        if (r && !r.estErreur) {
            refItems.current = r;
            setColumns(dataColumns);
            setItems(r);
        }
    }

    async function chargementDonneesGroupe() {
        const r = await getApiResponseJson(() => groupeApi.lister());
        if (r && !r.estErreur) {
            setGroupes(r);
            if (r.length === 1)
                setCodeGroupe(r[0].codeGroupe)
        }
    }

    async function chargementDonneesRayon(groupeCode) {
        const r = await getApiResponseJson(() => rayonApi.lister(groupeCode));
        if (r && !r.estErreur) {
            setRayons(r);
            if (r.length === 1)
                setCodeRayon(r[0].codeRayon)
        }
    }

    async function chargementDonneesFamille(rayonCode) {
        const r = await getApiResponseJson(() => familleApi.listerFamilleSansPerimetreIndividuel(codeMagasin, annee, mois, rayonCode, matricule));
        if (r && !r.estErreur) {
            setFamilles(r);
            if (r.length === 1)
                setCodeFamille(r[0].codeFamille)
        }
    }

    async function ajouterSpi() {
        if (!codeFamille) {
            setWarningMessage("La famille n'est pas renseignée.")
        }

        const r = await getApiResponseJson(() => salariePerimetreIndividuelApi.creer({ codeMagasin, annee, mois, codeFamille, matricule }));
        if (r && !r.estErreur) {
            setItems(st => {
                st.splice(0, 0, r);
                return st;
            })
            setCodeFamille(null);
            chargementDonneesFamille(codeRayon);
        }
    }

    async function supprimerSpi() {
        const r = await getApiResponse(() => salariePerimetreIndividuelApi.supprimer(codeMagasin, annee, mois, matricule, codeFamilleSuppression));
        if (r && r.ok) {
            setItems(st => st.filter(spi => spi.codeFamille !== codeFamilleSuppression))
        }

    }

    async function copierSalarie(matriculeCopieReference) {
        const r = await getApiResponse(() => salariePerimetreIndividuelApi.copier({ annee, mois, matricule, matriculeCopieReference }));
        if (r && r.ok) {
            chargementDonnees(codeMagasin, annee, mois, matricule)
        }
    }

    const groupeOptions = useMemo(() => groupes.map(g => ({ key: g.codeGroupe, text: g.nomGroupe })), [groupes])
    const rayonOptions = useMemo(() => rayons.map(r => ({ key: r.codeRayon, text: r.nomRayon })), [rayons])
    const familleOptions = useMemo(() => familles.map(f => ({ key: f.codeFamille, text: f.nomFamille })), [familles])

    const creationContent = () => {
        const itemsFiltered = (!items ? [] : (items.length > 0 ? items.filter(i => contains(rechercheLibreFilter, i.email)) : items));

        return (
            <Fragment>
                <h2>Périmètre individuel</h2>
                <CommandBar
                    items={getCommandBarItems}
                    farItems={getCommandBarFarItems}
                />
                <DetailsList
                    setKey="items"
                    items={itemsFiltered}
                    columns={[...columns, ...actionColumns]}
                    selectionMode={SelectionMode.none}
                    onRenderRow={Tools.onRenderRowLignesAlternees}
                />
                <DialogOkCancel
                    visible={dialogSuppressionVisible}
                    title='Suppression'
                    question='Confirmez-vous la suppression de cet élément ?'
                    onOk={() => {
                        supprimerSpi();
                        onDissimuleSuppressionDialog();
                    }}
                    onCancel={onDissimuleSuppressionDialog}
                />
                <DialogCopieSalarie
                    matricule={matricule}
                    visible={dialogCopieSalarieVisible}
                    onCancel={() => setDialogCopieSalarieVisible(false)}
                    onOk={(matriculeRef) => copierSalarie(matriculeRef)}
                />
            </Fragment>)
    }

    function onAfficheSuppressionDialog(item) {
        setCodeFamilleSuppression(item.codeFamille);
        setDialogSuppressionVisible(true);
    }
    function onDissimuleSuppressionDialog() {
        setCodeFamilleSuppression(null);
        setDialogSuppressionVisible(false);
    }

    const actionColumns = [
        {
            key: 'supprimer',
            name: 'Supprimer',
            ariaLabel: 'Supprimer',
            minWidth: 20,
            maxWidth: 40,
            isIconOnly: true,
            isSorted: false,
            onRender: (item) => (
                <IconButton
                    iconProps={{ iconName: 'Delete' }}
                    title="Supprimer"
                    ariaLabel="Supprimer"
                    onClick={() => onAfficheSuppressionDialog(item)}
                />
            )
        }
    ];

    function _onColumnClick(ev, column) {
        const sortedValues = Tools.sortColumns(refItems.current, columns, column);
        setItems(sortedValues[0]);
        setColumns(sortedValues[1]);
    }

    const getCommandBarItems = [
        {
            key: 'selectionFamille',
            onRender: () => <>
                <ComboBox
                    placeholder="Sélectionnez un groupe"
                    selectedKey={codeGroupe}
                    options={groupeOptions}
                    onChange={(e, opt, i, v) => {
                        setCodeGroupe(opt.key)
                    }}
                    disabled={groupeOptions.length < 2}
                    styles={{ root: { marginRight: '0.5rem' } }}
                />
                <ComboBox
                    placeholder="Sélectionnez un rayon"
                    selectedKey={codeRayon}
                    options={rayonOptions}
                    onChange={(e, opt, i, v) => {
                        setCodeRayon(opt.key)
                    }}
                    disabled={rayonOptions.length < 2}
                    styles={{ root: { marginRight: '0.5rem' } }}
                />
                <ComboBox
                    placeholder="Sélectionnez une famille"
                    selectedKey={codeFamille}
                    options={familleOptions}
                    onChange={(e, opt, i, v) => {
                        setCodeFamille(opt.key)
                    }}
                    disabled={familleOptions.length < 2}
                />
            </>
        },
        {
            key: 'AjouterFamille',
            text: 'Ajouter',
            iconProps: { iconName: 'Add' },
            onClick: () => {
                ajouterSpi(codeFamille, matricule);
            },
            disabled: familleOptions.length < 1,
            buttonStyles: { root: { padding: '0.5rem', marginLeft: '0.2rem' } },
        }
    ];
    const getCommandBarFarItems = [
        {
            key: 'CopieSalarie',
            text: 'Copier depuis...',
            iconProps: { iconName: 'Copy' },
            preferMenuTargetAsEventTarget: true,
            onClick: () => setDialogCopieSalarieVisible(true),
            disabled: !possedeSecteur,
            buttonStyles: { root: { padding: '0.5rem', marginLeft: '0.2rem' } },
        }
    ];

    return (creationContent());
}

export default PerimetreIndividuelListe