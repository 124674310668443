import React, { Fragment, useEffect, useState, useContext, useMemo, useRef } from 'react';
import { DetailsList, IconButton, CommandBar, SelectionMode, Separator, Checkbox, ComboBox, Label, Link, Modal, Toggle } from '@fluentui/react';
import useVenteMagasinFamilleApi from '../../apis/useVenteMagasinFamilleApi';
import useSecteurApi from '../../apis/useSecteurApi';
import useMagasinFamilleEstDemontreApi from '../../apis/useMagasinFamilleEstDemontreApi';
import useSecteurFamilleApi from '../../apis/useSecteurFamilleApi';
import useGroupeApi from '../../apis/useGroupeApi';
import useRayonApi from '../../apis/useRayonApi';
import { scriba, ScribaReactContexts } from 'react-scriba-fluentui-web'
import Tools from '../tools';
import { HandleMagasinMoisAnneeContext } from '../../ScribaSpecific/HandleMagasinMoisAnnee'
import Bouton from '../Generique/Bouton';
import AuthorizeComponent from '../Generique/AuthorizeComponent';
import { policies } from '../constants';

const AffectationFamilleParSecteurListe = (props) => {

    const { contains } = scriba.ScribaOutils;
    const { setErrorMessage, setSuccessMessage, withLoading, getApiResponseJson, getApiResponse } = ScribaReactContexts.GlobalActions.useScribaGlobalActions();
    const venteMagasinFamilleApi = useVenteMagasinFamilleApi();
    const secteurApi = useSecteurApi();
    const magasinFamilleEstDemontreApi = useMagasinFamilleEstDemontreApi();
    const secteurFamilleApi = useSecteurFamilleApi();
    const groupeApi = useGroupeApi();
    const rayonApi = useRayonApi();
    const { codeMagasin, mois, annee } = useContext(HandleMagasinMoisAnneeContext);

    const dataColumns = [
        {
            key: 'Affecte',
            name: 'Affecté',
            ariaLabel: 'Affecte',
            minWidth: 200,
            maxWidth: 300,
            onColumnClick: _onColumnClick,
            isResizable: true,
            onRender: (item) => {
                console.log(item.salariePerimetreIndividuelExiste)
                return <Checkbox
                    disabled={!idSecteur || item.estAffecteAutreSecteur}
                    checked={item.estAffecte}
                    onChange={(elem, checked) => checked ? onAfficheModal(item) : onSupprimeEstAffecte({ ...item, affecte: checked })
                    } />
            }
        },
        {
            key: 'EstAffecteAutreSecteur',
            name: 'Affecté à un autre secteur',
            ariaLabel: 'EstAffecteAutreSecteur',
            minWidth: 200,
            maxWidth: 300,
            onColumnClick: _onColumnClick,
            isResizable: true,
            onRender: (item) => {
                return item.estAffecteAutreSecteur && "X";
            }
        },
        {
            key: 'NomGroupe',
            name: 'Groupe',
            ariaLabel: 'NomGroupe',
            fieldName: 'nomGroupe',
            minWidth: 200,
            maxWidth: 300,
            onColumnClick: _onColumnClick,
            isResizable: true
        },
        {
            key: 'NomRayon',
            name: 'Rayon',
            ariaLabel: 'NomRayon',
            fieldName: 'nomRayon',
            minWidth: 200,
            maxWidth: 300,
            onColumnClick: _onColumnClick,
            isResizable: true
        },
        {
            key: 'CodeFamille',
            name: 'Code famille',
            ariaLabel: 'CodeFamille',
            fieldName: 'codeFamille',
            minWidth: 200,
            maxWidth: 300,
            onColumnClick: _onColumnClick,
            isResizable: true
        },
        {
            key: 'NomFamille',
            name: 'Famille',
            ariaLabel: 'NomFamille',
            fieldName: 'nomFamille',
            minWidth: 200,
            maxWidth: 300,
            onColumnClick: _onColumnClick,
            isResizable: true
        },
        {
            key: 'StatutFamille',
            name: 'Statut',
            ariaLabel: 'StatutFamille',
            fieldName: 'statutFamille',
            minWidth: 200,
            maxWidth: 300,
            onColumnClick: _onColumnClick,
            isResizable: true
        },
        {
            key: 'EstDemontre',
            name: 'Est démontré',
            ariaLabel: 'EstDemontre',
            minWidth: 200,
            maxWidth: 300,
            onColumnClick: _onColumnClick,
            isResizable: true,
            onRender: (item) => {
                return <Checkbox checked={item.estDemontre} onChange={(elem, checked) => checked ? onEstDemontre({ ...item, estDemontre: checked }) : onSupprimeEstDemontre({ ...item, estDemontre: checked })} />
            }
        }
    ];

    const [items, setItems] = useState([]);
    const [columns, setColumns] = useState(dataColumns);
    const [secteurs, setSecteurs] = useState([]);
    const [idSecteur, setIdSecteur] = useState(null);
    const [groupes, setGroupes] = useState(null);
    const [codeGroupe, setCodeGroupe] = useState(null);
    const [rayons, setRayons] = useState(null);
    const [codeRayon, setCodeRayon] = useState(null);
    const [responsableSecteur, setResponsableSecteur] = useState(null);
    const [affectationForceAssociation, setAffectationForceAssociation] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [itemSelectionAffecte, setItemSelectionAffecte] = useState(null);
    const refItems = useRef(items);

    useEffect(() => {
        if (codeMagasin && mois && annee) {
            withLoading(() => chargementDonnees());
        }
    }, [codeMagasin, mois, annee, idSecteur, codeGroupe, codeRayon]);

    useEffect(() => {
        if (idSecteur)
            chargementResponsableDeSecteur()
    }, [idSecteur]);

    useEffect(() => {
        if (codeMagasin) {
            chargementComboBoxSecteur();
            chargementComboBoxGroupe();
        }
    }, [codeMagasin]);

    useEffect(() => {
        if (codeGroupe) {
            chargementComboBoxRayon();
        }
    }, [codeGroupe]);

    async function chargementDonnees() {
        const r = await getApiResponseJson(() => venteMagasinFamilleApi.listerComplet(codeMagasin, mois, annee, idSecteur, codeGroupe, codeRayon));

        if (r && !r.estErreur) {
            refItems.current = r;
            setColumns(dataColumns);
            setItems(r);
        }
    }
    async function chargementDonneesPartielEstAffecte(codeFamille, estAffecte) {
        setItems(st => st.map(x => {
            if (x.codeMagasin == codeMagasin && x.codeFamille == codeFamille && x.mois == mois && x.annee == annee)
                x.estAffecte = estAffecte;
            return x;
        }));

    }
    async function chargementDonneesPartielEstDemontre(codeFamille, estDemontre) {
        setItems(st => st.map(x => {
            if (x.codeMagasin == codeMagasin && x.codeFamille == codeFamille && x.mois == mois && x.annee == annee)
                x.estDemontre = estDemontre;
            return x;
        }));
    }

    async function chargementComboBoxSecteur() {
        const r = await getApiResponseJson(() => secteurApi.listerParMagasin(codeMagasin));

        if (r && !r.estErreur) {
            setSecteurs(r);
        }
    }
    async function chargementResponsableDeSecteur() {
        const r = await getApiResponseJson(() => secteurApi.detailler(idSecteur, codeMagasin));

        if (r && !r.estErreur) {
            setResponsableSecteur(r.nomSalarieResponsableSecteur);
        }
    }
    async function chargementComboBoxGroupe() {
        const r = await getApiResponseJson(() => groupeApi.listerParMagasin(codeMagasin, mois, annee));

        if (r && !r.estErreur) {
            setGroupes(r);
        }
    }
    async function chargementComboBoxRayon() {
        const r = await getApiResponseJson(() => rayonApi.lister(codeGroupe));

        if (r && !r.estErreur) {
            setRayons(r);
        }
    }

    async function onEstAffecte(item) {
        const r = await getApiResponseJson(() => secteurFamilleApi.creer({ codeMagasin: item.codeMagasin, codeFamille: item.codeFamille, mois: item.mois, annee: item.annee, idSecteur: idSecteur, affectationForceAssociation: affectationForceAssociation }));

        if (r && !r.estErreur) {
            setSuccessMessage(`L'élément a été modifié avec succès.`);
            onDissimuleModal();
            chargementDonneesPartielEstAffecte(item.codeFamille, true);
        }
    }
    async function onSupprimeEstAffecte(item) {
        const r = await getApiResponse(() => secteurFamilleApi.supprimer(codeMagasin, item.codeFamille, annee, mois, idSecteur));

        if (r.ok) {
            setSuccessMessage(`L'élément a été modifié avec succès.`);
            chargementDonneesPartielEstAffecte(item.codeFamille, false);
        }
    }

    async function onEstDemontre(item) {
        const r = await getApiResponseJson(() => magasinFamilleEstDemontreApi.creer({ codeMagasin: item.codeMagasin, codeFamille: item.codeFamille, mois: item.mois, annee: item.annee }));

        if (r && !r.estErreur) {
            setSuccessMessage(`L'élément a été modifié avec succès.`);
            chargementDonneesPartielEstDemontre(item.codeFamille, item.estDemontre);
        }
    }
    async function onSupprimeEstDemontre(item) {
        const r = await getApiResponse(() => magasinFamilleEstDemontreApi.supprimer(codeMagasin, item.codeFamille, annee, mois));

        if (r.ok) {
            setSuccessMessage(`L'élément a été modifié avec succès.`);
            chargementDonneesPartielEstDemontre(item.codeFamille, item.estDemontre);
        }
    }

    const secteurOptions = useMemo(() => [{ key: null, text: 'Sélectionnez un secteur' }, ...secteurs.map((e) => ({ key: e.idSecteur, text: e.nomSecteur }))], [secteurs]);
    const groupeOptions = useMemo(() => groupes && [{ key: null, text: 'Sélectionnez un groupe' }, ...groupes.map((e) => ({ key: e.codeGroupe, text: e.nomGroupe }))], [groupes]);
    const rayonOptions = useMemo(() => rayons && [{ key: null, text: 'Sélectionnez un rayon' }, ...rayons.map((e) => ({ key: e.codeRayon, text: e.nomRayon }))], [rayons]);

    const creationContent = () => {
        return (
            <AuthorizeComponent fonctionnalite={policies.magasinFamilleSecteur}>
                <CommandBar
                    items={getCommandBarItems}
                />
                <Separator />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', margin: '1rem' }}>
                    <ComboBox
                        label="Secteur"
                        className="col-2"
                        selectedKey={idSecteur}
                        options={secteurOptions}
                        onChange={(e, opt, i, v) => {
                            setIdSecteur(opt.key)
                        }}
                    />
                    {responsableSecteur &&
                        <>
                            <span style={{ marginLeft: '10px', marginRight: '10px', fontWeight: '600', textDecoration: 'underline', marginTop:'25px' }}>Responsable de secteur</span>
                        <span style={{ marginTop: '25px'}}>{responsableSecteur}  </span>
                        </>}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', margin: '1rem' }}>
                    <ComboBox
                        label="Groupe"
                        className="col-2"
                        style={{ marginRight: '10px' }}
                        selectedKey={codeGroupe}
                        options={groupeOptions}
                        onChange={(e, opt, i, v) => {
                            setCodeGroupe(opt.key)
                        }}
                        disabled={!groupeOptions}
                    />
                    <ComboBox
                        label="Rayon"
                        className="col-2"
                        selectedKey={codeRayon}
                        options={rayonOptions}
                        onChange={(e, opt, i, v) => {
                            setCodeRayon(opt.key)
                        }}
                        disabled={!rayonOptions}
                    />
                </div>
                <DetailsList
                    setKey="items"
                    items={items}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                    onRenderRow={Tools.onRenderRowLignesAlternees}
                />
                <Modal
                    isOpen={modalVisible}
                    onDismiss={onDissimuleModal}
                    isBlocking={true}
                    allowTouchBodyScroll={true}
                    styles={{ main: { width: 500, padding: 15 } }}
                >
                    <Toggle
                        label="Voulez-vous forcer l'association de périmètre individuel a chaque salarié du secteur ?"
                        className="col-12"
                        checked={affectationForceAssociation}
                        onChange={(e, v) => setAffectationForceAssociation(v)}
                        onText="Oui"
                        offText="Non"
                    />
                    <div className="col-12" style={{ marginTop: '45px' }}>
                        <Bouton type="Enregistrer" onClick={() => onEstAffecte(itemSelectionAffecte)} />
                        <Bouton type="Annuler" onClick={() => onDissimuleModal()} />
                    </div>
                </Modal>
            </AuthorizeComponent>)
    }

    function onAfficheModal(item) {
        setItemSelectionAffecte(item);
        setModalVisible(true);
    }
    function onDissimuleModal() {
        setItemSelectionAffecte(null);
        setModalVisible(false);
    }

    function _onColumnClick(ev, column) {
        const sortedValues = Tools.sortColumns(refItems.current, columns, column);
        setItems(sortedValues[0]);
        setColumns(sortedValues[1]);
    }

    const getCommandBarItems = [
        {
            key: 'title',
            onRender: () => <h2>Magasin - Affectation de familles à un secteur</h2>
        }
    ];

    return (creationContent());
}

export default AffectationFamilleParSecteurListe