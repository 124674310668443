export const policies = {
    administrationUtilisateur: "AdministrationUtilisateur",
    administrationFamilleEmploiPrime: "AdministrationFamilleEmploiPrime",
    administrationCalculPrime: "AdministrationCalculPrime",
    magasinInitialisationDesDonnees: "MagasinInitialisationDesDonnees",
    magasinFamilleSecteur: "MagasinFamilleSecteur",
    magasinSalariePerimetreIndividuel: "MagasinSalariePerimetreIndividuel",
    magasinRepartitionCa: "MagasinRepartitionCa",
    magasinAccelerateurPrime: "MagasinAccelerateurPrime",
    salarieConsultation: "SalarieConsultation"
}