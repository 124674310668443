import { useContext } from 'react';
import { HandleProfilContext } from './HandleProfil';

export default function useProfil() {
    const [profil] = useContext(HandleProfilContext);
    const { droits, policies } = profil;

    function peutAcceder(fct) {
        if (!fct)
            return false;

        const entries = Object.entries(droits);
        const droitFct = entries.find(d => {
            const [key, value] = d;
            return key === fct
        });
        if (!droitFct || droitFct.length < 1)
            return false;

        const [key, estAutorise] = droitFct;
        //console.log("peutAcceder autor.", estAutorise)
        return estAutorise === true;
    }
    
    return {
        peutAcceder,
        policies
    }
}