import { useContext } from 'react';
import { HandleAuthenticationContext } from '../ScribaSpecific/HandleAuthentication';
const getLanguageHeader = () => '';

export default function useSecteurFamilleApi() {
    const { token } = useContext(HandleAuthenticationContext);
    const baseUrl = 'api/secteurFamille';

    const creer = async (item) => {
        var url = `${baseUrl}`;
        var parameters = {
            method: 'POST',
            body: JSON.stringify(item),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                ...await getLanguageHeader()
            },
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    }

    const supprimer = async (codeMagasin, codeFamille, annee, mois, idSecteur) => {
        var url = `${baseUrl}/${codeMagasin}/${codeFamille}/${annee}/${mois}/${idSecteur}`;
        var parameters = {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                ...await getLanguageHeader()
            },
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    }

    return {
        creer, supprimer
    }
}