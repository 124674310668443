import React, { Component } from 'react';

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <div>
                <h2>Accueil</h2>
                <p>Avancement général du projet web</p>
                <p><em>Etat d'avancement du 19/12/2022.</em></p>
                <table className="avancement">
                    <thead>
                        <tr><th>Domaine</th><th>Nom de l'écran</th><th>Statut</th></tr>
                    </thead>
                    <tbody>
                        <tr><td>Administration</td><td>Gestion des utilisateurs</td><td class="statutTermine">Terminé</td></tr>
                        <tr><td>Administration</td><td>Gestion des familles d'emploi / prime</td><td class="statutTermine">Terminé</td></tr>
                        <tr><td>Administration</td><td>Calcul des primes</td><td class="statutTermine">Terminé</td></tr>
                        <tr><td>Magasin</td><td>Initialisation des données</td><td class="statutTermine">Terminé</td></tr>
                        <tr><td>Magasin</td><td>Famille / secteur</td><td class="statutTermine">Terminé</td></tr>
                        <tr><td>Magasin</td><td>Périmètre individuel</td><td class="statutTermine">Terminé</td></tr>
                        <tr><td>Magasin</td><td>Répartition CA</td><td class="statutTermine">Terminé</td></tr>
                        <tr><td>Magasin</td><td>Accélérateur de primes</td><td class="statutTermine">Terminé</td></tr>
                        <tr><td>Salarié</td><td>Consultation des primes</td><td class="statutTermine">Terminé</td></tr>
                    </tbody>
                    <tfoot>
                    </tfoot>
                </table>
            </div>
        );
    }
}
