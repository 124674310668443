import { useContext } from 'react';
import { HandleAuthenticationContext } from '../ScribaSpecific/HandleAuthentication';
const getLanguageHeader = () => '';

export default function useMagasinApi() {
    const { token } = useContext(HandleAuthenticationContext);
    const baseUrl = 'api/magasin';

    const listerParPersonne = async (idPersonne, annee, mois) => {
        var url = `${baseUrl}/personne/${idPersonne}?annee=${annee|0}&mois=${mois|0}&codeMagasin=null`;
        var parameters = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
                ...await getLanguageHeader()
            },
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    };

    const lister = async () => {
        var url = `${baseUrl}`;
        var parameters = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
                ...await getLanguageHeader()
            },
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    };

    const detailler = async (codeMagasin) => {
        var url = `${baseUrl}/${codeMagasin}`;
        var parameters = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
                ...await getLanguageHeader()
            },
            mode: 'cors',
            cache: 'default'
        };

        return await fetch(url, parameters);
    };


    return {
        listerParPersonne, lister, detailler
    }
}