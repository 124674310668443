import React, { Fragment, useEffect, useState, useRef } from 'react';
import { DetailsList, IconButton, CommandBar, SelectionMode, Separator, Checkbox } from '@fluentui/react';
import usePersonneApi from '../../apis/usePersonneApi';
import PersonneDetail from './PersonneDetail';
import { scriba, ScribaReactContexts, ScribaFluentUiComposants } from 'react-scriba-fluentui-web'
import Tools from '../tools';
import RechercheLibre from '../Generique/RechercheLibre';
import { policies } from '../constants';
import AuthorizeComponent from '../Generique/AuthorizeComponent';

const PersonneListe = (props) => {

    const { DialogOkCancel } = ScribaFluentUiComposants;
    const { contains } = scriba.ScribaOutils;
    const { setErrorMessage, setSuccessMessage, withLoading, getApiResponseJson, getApiResponse } = ScribaReactContexts.GlobalActions.useScribaGlobalActions();
    const personneApi = usePersonneApi();

    const [items, setItems] = useState([]);

    const dataColumns = [
        {
            key: 'Email',
            name: 'Email',
            ariaLabel: 'Email',
            fieldName: 'email',
            minWidth: 200,
            maxWidth: 300,
            onColumnClick: _onColumnClick,
            isResizable: true
        },

        {
            key: 'NomSalarie',
            name: 'Salarié',
            ariaLabel: 'NomSalarie',
            fieldName: 'nomSalarie',
            minWidth: 200,
            maxWidth: 300,
            onColumnClick: _onColumnClick,
            isResizable: true
        },
        {
            key: 'Administrateur',
            name: 'Administrateur',
            ariaLabel: 'Administrateur',
            minWidth: 100,
            maxWidth: 200,
            onColumnClick: _onColumnClick,
            isResizable: true,
            onRender: (item) => {
                return <Checkbox checked={item.administrateurGlobal} onChange={(elem, checked) => onEnregistrer({ ...item, administrateurGlobal: checked })} />
            }
        },
        {
            key: 'EstResponsableMagasin',
            name: 'Responsable magasin',
            ariaLabel: 'EstResponsableMagasin',
            minWidth: 100,
            maxWidth: 200,
            onColumnClick: _onColumnClick,
            isResizable: true,
            onRender: (item) => {
                return item.estResponsableMagasin ? "Oui" : "Non";
            }
        },
        {
            key: 'EstResponsableSecteur',
            name: 'Responsable secteur',
            ariaLabel: 'EstResponsableSecteur',
            minWidth: 100,
            maxWidth: 200,
            onColumnClick: _onColumnClick,
            isResizable: true,
            onRender: (item) => {
                return item.estResponsableSecteur ? "Oui" : "Non";
            }
        }
    ];

    const [columns, setColumns] = useState(dataColumns);
    const [panelDetailVisible, setPanelDetailVisible] = useState(false);
    const [rechercheLibreFilter, setRechercheLibreFilter] = useState('');
    const [dialogSuppressionVisible, setDialogSuppressionVisible] = useState(false);
    const [idPersonne, setIdPersonne] = useState(null);
    const refItems = useRef(items);

    useEffect(() => {
        withLoading(() => chargementDonnees());
    }, []);

    async function chargementDonnees() {
        const r = await getApiResponseJson(() => personneApi.listerComplet());

        if (r && !r.estErreur) {
            refItems.current = r;
            setColumns(dataColumns);
            setItems(r);
        }
    }

    async function chargementDonneesPartiel(elem) {
        setItems(st => st.map(x => {
            if (x.idPersonne == elem.idPersonne)
                x.administrateurGlobal = elem.administrateurGlobal;
            return x;
        }));
    }

    async function onEnregistrer(item) {
        const r = await getApiResponseJson(() => personneApi.modifier(item));

        if (r && !r.estErreur) {
            setSuccessMessage(`Modification effectuée avec succès.`);
            chargementDonneesPartiel(r);
        }
    }

    async function onSupprimer() {
        withLoading(() => getApiResponse(() => personneApi.supprimer(idPersonne)).then(r => {
            if (r.ok) {
                onDissimuleSuppressionDialog();
                setSuccessMessage("Suppression effectuée avec succès.");

                setItems(st => st.filter(x => {
                    return x.idPersonne !== idPersonne
                }));
            }
        }));
    }

    const creationContent = () => {
        const itemsFiltered = (!items ? [] : (items.length > 0 ? items.filter(i => contains(rechercheLibreFilter, i.email)) : items));

        return (
            <AuthorizeComponent fonctionnalite={policies.administrationUtilisateur}>
                <CommandBar
                    items={getCommandBarItems}
                    farItems={getCommandBarFarItems}
                />
                <Separator />
                <RechercheLibre
                    disabled={!items || items.length < 1}
                    value={rechercheLibreFilter}
                    onChange={(e) => setRechercheLibreFilter(e)}
                    customStyles={{ width: 400, height: 40, marginTop: '10px' }}
                />
                <DetailsList
                    setKey="items"
                    items={itemsFiltered}
                    columns={[...columns, ...actionColumns]}
                    selectionMode={SelectionMode.none}
                    onRenderRow={Tools.onRenderRowLignesAlternees}
                />
                <PersonneDetail
                    visible={panelDetailVisible}
                    idPersonne={idPersonne}
                    onFermer={onDissimuleEditPanel}
                    onSucces={chargementDonnees}
                />
                <DialogOkCancel
                    visible={dialogSuppressionVisible}
                    title='Suppression'
                    question='Confirmez-vous la suppression de cet élément ?'
                    onOk={() => onSupprimer()}
                    onCancel={onDissimuleSuppressionDialog}
                />
            </AuthorizeComponent>)
    }

    function onAfficheEditPanel(item) {
        setIdPersonne(item == null ? null : item.idPersonne);
        setPanelDetailVisible(true);
    }
    function onDissimuleEditPanel() {
        setIdPersonne(null);
        setPanelDetailVisible(false);
    }

    function onAfficheSuppressionDialog(item) {
        setIdPersonne(item == null ? null : item.idPersonne);
        setDialogSuppressionVisible(true);
    }
    function onDissimuleSuppressionDialog() {
        setIdPersonne(null);
        setDialogSuppressionVisible(false);
    }

    const actionColumns = [
        {
            key: 'modifier',
            name: '',
            ariaLabel: 'Modifier',
            minWidth: 20,
            maxWidth: 40,
            isIconOnly: true,
            isSorted: false,
            onRender: (item) => (
                <IconButton
                    iconProps={{ iconName: 'OpenPane' }}
                    title="Modifier"
                    ariaLabel="Modifier"
                    onClick={() => onAfficheEditPanel(item)}
                />
            )
        },
        {
            key: 'supprimer',
            name: '',
            ariaLabel: 'Supprimer',
            minWidth: 20,
            maxWidth: 40,
            isIconOnly: true,
            isSorted: false,
            onRender: (item) => (
                <IconButton
                    iconProps={{ iconName: 'Delete' }}
                    title="Supprimer"
                    ariaLabel="Supprimer"
                    onClick={() => onAfficheSuppressionDialog(item)}
                />
            )
        }
    ];

    function _onColumnClick(ev, column) {
        const sortedValues = Tools.sortColumns(refItems.current, columns, column);
        setItems(sortedValues[0]);
        setColumns(sortedValues[1]);
    }

    const getCommandBarItems = [
        {
            key: 'title',
            onRender: () => <h2>Administration - Utilisateurs</h2>
        }
    ];
    const getCommandBarFarItems = [
        {
            key: 'add',
            name: 'Nouvel utilisateur',
            iconProps: {
                iconName: 'Add'
            },
            onClick: () => onAfficheEditPanel(null),
            buttonStyles: Tools.commandBarButtonStyles
        }
    ];

    return (creationContent());
}

export default PersonneListe