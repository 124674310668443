import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ComboBox, DefaultButton, Label, Modal, PrimaryButton, SelectableOptionMenuItemType } from '@fluentui/react';
import { scriba, ScribaFluentUiComposants, ScribaReactContexts } from 'react-scriba-fluentui-web';
import useMagasinApi from '../../apis/useMagasinApi';
import { HandleMagasinMoisAnneeContext } from '../../ScribaSpecific/HandleMagasinMoisAnnee';

export default function DialogInitialisationDonnees(props) {
    const {
        codeMagasin,
        mois,
        annee,
        visible,
        onCancel,
        onOk
    } = props;

    const { getApiResponseJson } = ScribaReactContexts.GlobalActions.useScribaGlobalActions();
    const magasinApi = useMagasinApi();

    const [magasin, setMagasin] = useState([]);

    useEffect(() => {
        if (visible && codeMagasin)
            chargementDonneesMagasin(codeMagasin);

    }, [codeMagasin, visible]);

    async function chargementDonneesMagasin(code) {
        const r = await getApiResponseJson(() => magasinApi.detailler(code));
        if (r && !r.estErreur) {
            setMagasin(r);
        }
    }

    async function lancerInitialisation() {
        await onOk();
        onCancel();
    }

    const dateM = useMemo(() => {
        const dJour = new Date(annee, mois - 1);
        return dJour
    }, [annee, mois])
    const dateMMoinsUn = useMemo(() => {
        const dJour = new Date(annee, mois - 1);
        dJour.setMonth(dJour.getMonth()-1);
        return dJour
    }, [annee, mois])

    return <Modal
        isOpen={visible}
        onDismiss={onCancel}
        isBlocking={true}
        allowTouchBodyScroll={true}
        styles={{ main: { maxWidth: 800, padding: '1rem' } }}
    >
        <div>
            <h3>Initialisation des données </h3>
        </div>
        <div style={{ marginBottom: '1rem' }}>
            <div>{`Vous allez lancer la recopie des données de paramétrage du magasin ${magasin?.nomMagasin}`}</div>
            <ul>
                <li>à partir de la période <strong>{`${dateMMoinsUn.getFullYear()}-${scriba.ScribaDate.stringDateFormat(dateMMoinsUn, 'MMMM')}`}</strong> (source)</li>
                <li>vers la période <strong>{`${dateM.getFullYear()}-${scriba.ScribaDate.stringDateFormat(dateM, 'MMMM')}`}</strong> (cible)</li>
            </ul>
            <div>Cette recopie contiendra les associations de données suivantes :</div>
            <ul>
                <li>Familles / secteurs </li>
                <li>Périmètre individuel des salariés</li>
                <li>Est démontré</li>
            </ul>
            <p><strong>Confirmez vous la recopie de ces données ?</strong></p>
        </div>
        <div style={{ marginTop: '1rem' }}>
            <PrimaryButton onClick={() => lancerInitialisation()} text="Oui" />
            <DefaultButton onClick={onCancel} text="Non" />
        </div>
    </Modal>;
}