export function createCustomAppTheme(isDarkMode) {
    const themeClair = {
        themePrimary: '#2f57a4',
        themeLighterAlt: '#f4f7fb',
        themeLighter: '#d5dff0',
        themeLight: '#b3c4e3',
        themeTertiary: '#7391c8',
        themeSecondary: '#4167ae',
        themeDarkAlt: '#2b4f93',
        themeDark: '#24437c',
        themeDarker: '#1b315b',
        neutralLighterAlt: '#f4f4f4',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff'
    };

    const themeSombre = {
        themePrimary: '#7ca6f7',
        themeLighterAlt: '#05070a',
        themeLighter: '#141a28',
        themeLight: '#25314a',
        themeTertiary: '#4a6394',
        themeSecondary: '#6d91da',
        themeDarkAlt: '#88aef8',
        themeDark: '#9abaf9',
        themeDarker: '#b5ccfb',
        neutralLighterAlt: '#414141',
        neutralLighter: '#484848',
        neutralLight: '#555555',
        neutralQuaternaryAlt: '#5d5d5d',
        neutralQuaternary: '#636363',
        neutralTertiaryAlt: '#7e7e7e',
        neutralTertiary: '#c8c8c8',
        neutralSecondary: '#d0d0d0',
        neutralPrimaryAlt: '#dadada',
        neutralPrimary: '#ffffff',
        neutralDark: '#f4f4f4',
        black: '#f8f8f8',
        white: '#393939',
        green: '#21af33',
        red: '#ff5250'
    };

    const paletteMode = (isDarkMode ? themeSombre : themeClair);

    return {
        palette: paletteMode,

        // voir ça : https://developer.microsoft.com/en-us/fluentui#/controls/web/references/theme#ComponentsStyles
        // et ça : https://stackoverflow.com/questions/66126241/fluent-ui-react-how-to-apply-global-component-styles-with-fluent-themeprovider
        components: {
            Panel: {
                styles: {
                    commands: { backgroundColor: paletteMode.themePrimary, paddingTop: '9px', paddingBottom: '9px', marginTop: '0px!important' },
                    content: { paddingBottom: '4rem' }, // Taille du footer d'un panel avec le bouton de fermeture
                    headerText: { color: paletteMode.white },
                    closeButton: { color: paletteMode.white, backgroundColor: paletteMode.themePrimary, },
                    footer: { borderTop: `1px solid ${paletteMode.neutralLight}`, backgroundColor: paletteMode.white }
                }
            },
            CommandBar: {
                styles: {
                    primarySet: { alignItems: 'center' },
                    secondarySet: { alignItems: 'center' }
                },
            },
            IconButton: {
                styles: { root: { height: '1.5rem', color: paletteMode.themePrimary } }
            },
            DetailsList: {
                styles: {
                    root: {
                        selectors: {
                            // header sur plusieurs lignes si trop long
                            '.ms-DetailsHeader-cell': { whiteSpace: 'normal', textOverflow: 'clip', lineHeight: 'normal', },
                            '.ms-DetailsHeader-cellTitle': { height: '100%', alignItems: 'center', },
                        },
                    },
                }
            },
            PrimaryButton: {
                styles: { root: { borderRadius: '0.25rem', color: paletteMode.white } }
            },
            DefaultButton: {
                styles: { root: { borderRadius: '0.25rem', color: paletteMode.themePrimary, border: `1px solid ${paletteMode.themePrimary}` } }
            }
        },
        // https://github.com/microsoft/fluentui/wiki/Customizing-fonts
        defaultFontStyle: { fontFamily: 'Open Sans', fontWeight: 'regular' },
        fonts: {
            small: {
                fontSize: '11px'
            },
            medium: {
                fontSize: '13px'
            },
            large: {
                fontSize: '20px',
                fontWeight: 'semibold'
            },
            xLarge: {
                fontSize: '22px',
                fontWeight: 'semibold'
            }
        }
    };
}