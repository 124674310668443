import React from 'react';
import { SearchBox } from '@fluentui/react';

const RechercheLibre = (props) => {
    const { disabled, value, onChange, customStyles } = props;

    return (
        <>
            <SearchBox
                autoFocus
                disabled={disabled}
                styles={{ root: { ...customStyles } }}
                placeholder="Rechercher"
                value={value}
                onChange={(e, v) => onChange(v)}
            />
        </>)
}

export default RechercheLibre;